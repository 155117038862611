import Loading from "components/Loading";
import { MapboxGeoJSONPolygon } from "components/MapComponent";
import { useUser } from "hooks/useUser";
import { useWorkspaces } from "hooks/useWorkspaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "utils/toast";
import ProgressHeader from "../components/ProgressHeader";
import { GeographicalAreaMap } from "./GeographicalAreaMap";
import { PointsOfAnalysisMap } from "./PointsOfAnalysisMap";
import ProgressHeaderSuperAdmin from "../components/ProgressHeaderSuperAdmin";

export default function EditGeograficalArea() {
    const { authUser } = useUser();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(50);
    const [userNotIsSuperAdmin, setUserNotIsSuperAdmin] = useState(false);
    const { getWorkspacesByUuid, workspace, updateWorkspace } = useWorkspaces();
    const { uuid } = useParams<{ uuid: string }>();
    const navigate = useNavigate();

    const [geoGraphicalArea, setGeoGraphicalArea] = useState<
        MapboxGeoJSONPolygon | undefined
    >();
    const [pointsOfAnalysisPolygons, setPointsOfAnalysisPolygons] = useState<
        MapboxGeoJSONPolygon[] | undefined
    >();

    const handleGetWorkspacesByUuid = async () => {
        await getWorkspacesByUuid(uuid!);
        setIsLoading(false);
    }

    useEffect(() => {
        if (authUser?.roles && authUser?.roles !== "super_admin") {
            setStep(100);
            setUserNotIsSuperAdmin(true);
        }
    }, [authUser]);

    useEffect(() => {
        handleGetWorkspacesByUuid();
    }, [])

    const handlePrevious = () => {
        const value = Math.max(step - 50, 50);
        setStep(value);
    };

    const handleNext = () => {
        const value = Math.min(step + 50, 100);
        setStep(value);
    };

    // console.log("workspace", workspace.area_points)
    // console.log("pointsOfAnalysisPolygons", pointsOfAnalysisPolygons)

    useEffect(() => {
        setGeoGraphicalArea(workspace.status === "pending" ? workspace.region_map_area_pending : workspace.region_map_area || workspace.status !== "pending" ? workspace.region_map_area : workspace.region_map_area_pending);
    }, [workspace?.region_map_area, workspace?.region_map_area_pending, workspace?.status]);

    useEffect(() => {
        if (workspace && workspace.area_points?.length > 0) {
            const extractedPolygons = workspace.area_points
                .filter(item => workspace.status === "pending" && item?.region_map_area === null || workspace.status !== "pending" && item?.region_map_area !== null)
                .map(workspace.status === "pending" ? item => item.region_map_area_pending : item => item.region_map_area || workspace.status !== "pending" ? item.region_map_area : item.region_map_area_pending);
            setPointsOfAnalysisPolygons(extractedPolygons);
        } else {
            setPointsOfAnalysisPolygons([]);
        }
    }, [workspace]);

    async function handleUpdateWorkspace() {
        const mappedPoints: any = pointsOfAnalysisPolygons!.map(point => ({
            name: point.properties.name,
            json: JSON.stringify(point)
        }));
        const statusApproved = authUser?.roles === "super_admin" ? "approved" : null;
        //@ts-ignore
        const response = await updateWorkspace(uuid!, { region_map_area: JSON.stringify(geoGraphicalArea), region_map_area_points: mappedPoints, status: statusApproved });
        if (response) {
            toast({
                label: t('success'),
                message: t('council_updated_successfully'),
                type: "success",
            });
            navigate(`/workspace/${uuid}`)
        }
    }

    return (
        <>
            {isLoading ? (
                <>
                    <Loading />
                </>
            ) : (
                <main>
                    {userNotIsSuperAdmin ? (
                        <ProgressHeader
                            handleCreateNewWorkspace={handleUpdateWorkspace}
                            title={t('define_the_points_of_analysis')}
                            className="sticky pt-[32px] -mt-[32px] top-[80px] z-[11] bg-white"
                        />
                    ) : (
                        <ProgressHeaderSuperAdmin
                            step={step}
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                            handleCreateNewWorkspace={handleUpdateWorkspace}
                            title={step === 50 ? t('add_new_area') : t('define_the_points_of_analysis')}
                            className="sticky pt-[32px] -mt-[32px] top-[80px] z-[11] bg-white"
                        />
                    )}

                    <main className="w-full mx-auto pb-10">
                        {step === 50 && (
                            <>
                                <GeographicalAreaMap
                                    geoGraphicalArea={geoGraphicalArea}
                                    setGeoGraphicalArea={setGeoGraphicalArea}
                                />
                            </>
                        )}
                        {step === 100 && (
                            <>
                                <PointsOfAnalysisMap
                                    geoArea={geoGraphicalArea}
                                    pointsOfAnalysisPolygons={pointsOfAnalysisPolygons}
                                    setPointsOfAnalysisPolygons={setPointsOfAnalysisPolygons}
                                />
                            </>
                        )}
                    </main>

                </main>
            )}
        </>
    )
}
