import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { useUser } from "hooks/useUser";
import Permission from "utils/permission";
import { useWorkspaces, Workspace } from "hooks/useWorkspaces";

interface SidebarMobileProps {
  setOpenSettings: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SidebarMobile({ setOpenSettings }: SidebarMobileProps) {
  const navigate = useNavigate();
  const { logout, authUser } = useUser();
  const { setWorkspace } = useWorkspaces();
  const { t } = useTranslation();

  return (
    <div className="h-screen max-md:pb-8 bg-white z-50 overflow-y-auto">
      <div className="flex flex-col items-center justify-center gap-8">
        {authUser?.roles && authUser?.roles !== "external_service_provider" && (
          <>
            <button
              onClick={() => {
                navigate("/home");
                setOpenSettings(false);
              }}
              className="flex flex-col items-center gap-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M13 28.9998V21.9898C13 21.4998 13.5 20.9998 14 20.9998H18C18.5 20.9998 19 21.4998 19 21.9998V28.9998C19 29.2651 19.1054 29.5194 19.2929 29.7069C19.4804 29.8945 19.7348 29.9998 20 29.9998H28C28.2652 29.9998 28.5196 29.8945 28.7071 29.7069C28.8946 29.5194 29 29.2651 29 28.9998V14.9998C29.0002 14.8684 28.9746 14.7383 28.9245 14.6168C28.8744 14.4953 28.8008 14.3849 28.708 14.2918L26 11.5858V4.99983C26 4.73462 25.8946 4.48026 25.7071 4.29273C25.5196 4.10519 25.2652 3.99983 25 3.99983H23C22.7348 3.99983 22.4804 4.10519 22.2929 4.29273C22.1054 4.48026 22 4.73462 22 4.99983V7.58583L16.708 2.29183C16.6151 2.19871 16.5048 2.12482 16.3833 2.07441C16.2618 2.024 16.1315 1.99805 16 1.99805C15.8685 1.99805 15.7382 2.024 15.6167 2.07441C15.4952 2.12482 15.3849 2.19871 15.292 2.29183L3.292 14.2918C3.1992 14.3849 3.12564 14.4953 3.07554 14.6168C3.02543 14.7383 2.99977 14.8684 3 14.9998V28.9998C3 29.2651 3.10536 29.5194 3.29289 29.7069C3.48043 29.8945 3.73479 29.9998 4 29.9998H12C12.2652 29.9998 12.5196 29.8945 12.7071 29.7069C12.8946 29.5194 13 29.2651 13 28.9998Z"
                  fill="url(#paint0_linear_1254_5748)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1254_5748"
                    x1="3"
                    y1="15.9989"
                    x2="29"
                    y2="15.9989"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#00C6FF" />
                    <stop offset="1" stopColor="#0072FF" />
                  </linearGradient>
                </defs>
              </svg>
              <span className="font-comfortaa text-azulfy-rich-black text-sm">
                {t("home")}
              </span>
            </button>
            <span className="w-8 bg-grey-700 bg-opacity-50 h-[1px]" />
          </>
        )}
        {authUser?.roles && authUser?.roles == "admin" && (
          <>
            <button
              onClick={() => {
                navigate(`/workspace/${authUser?.workspaces?.uuid}`);
                setOpenSettings(false);
              }}
              className="flex flex-col items-center gap-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M14 28C14 28 12 28 12 26C12 24 14 18 22 18C30 18 32 24 32 26C32 28 30 28 30 28H14ZM22 16C23.5913 16 25.1174 15.3679 26.2426 14.2426C27.3679 13.1174 28 11.5913 28 10C28 8.4087 27.3679 6.88258 26.2426 5.75736C25.1174 4.63214 23.5913 4 22 4C20.4087 4 18.8826 4.63214 17.7574 5.75736C16.6321 6.88258 16 8.4087 16 10C16 11.5913 16.6321 13.1174 17.7574 14.2426C18.8826 15.3679 20.4087 16 22 16Z"
                  fill="url(#paint0_linear_1254_5744)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.432 27.9999C10.1355 27.3755 9.98765 26.691 10 25.9999C10 23.2899 11.36 20.4999 13.872 18.5599C12.6182 18.1735 11.3119 17.9846 10 17.9999C2 17.9999 0 23.9999 0 25.9999C0 27.9999 2 27.9999 2 27.9999H10.432Z"
                  fill="url(#paint1_linear_1254_5744)"
                />
                <path
                  d="M9 16C10.3261 16 11.5979 15.4732 12.5355 14.5355C13.4732 13.5979 14 12.3261 14 11C14 9.67392 13.4732 8.40215 12.5355 7.46447C11.5979 6.52678 10.3261 6 9 6C7.67392 6 6.40215 6.52678 5.46447 7.46447C4.52678 8.40215 4 9.67392 4 11C4 12.3261 4.52678 13.5979 5.46447 14.5355C6.40215 15.4732 7.67392 16 9 16Z"
                  fill="url(#paint2_linear_1254_5744)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1254_5744"
                    x1="12"
                    y1="16"
                    x2="32"
                    y2="16"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#00C6FF" />
                    <stop offset="1" stopColor="#0072FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1254_5744"
                    x1="0"
                    y1="22.9995"
                    x2="13.872"
                    y2="22.9995"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#00C6FF" />
                    <stop offset="1" stopColor="#0072FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1254_5744"
                    x1="4"
                    y1="11"
                    x2="14"
                    y2="11"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#00C6FF" />
                    <stop offset="1" stopColor="#0072FF" />
                  </linearGradient>
                </defs>
              </svg>
              <span className="font-comfortaa text-azulfy-rich-black text-sm">
                {t("council")}
              </span>
            </button>
            <span className="w-8 bg-grey-700 bg-opacity-50 h-[1px]" />
          </>
        )}
        <Permission permissions={['view message customer support']}>
          <>
            <button
              onClick={() => {
                navigate(`${authUser?.roles === "super_admin" ? "/support" : `${`/workspace/${authUser?.workspaces?.uuid}/support/${authUser?.support?.uuid}`}`}`);
                setOpenSettings(false);
              }}
              className="flex flex-col items-center gap-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M32 16C32 23.732 24.836 30 16 30C14.4153 30.0021 12.8372 29.7964 11.306 29.388C10.138 29.98 7.456 31.116 2.944 31.856C2.544 31.92 2.24 31.504 2.398 31.132C3.106 29.46 3.746 27.232 3.938 25.2C1.488 22.74 0 19.52 0 16C0 8.268 7.164 2 16 2C24.836 2 32 8.268 32 16ZM10 16C10 15.4696 9.78929 14.9609 9.41421 14.5858C9.03914 14.2107 8.53043 14 8 14C7.46957 14 6.96086 14.2107 6.58579 14.5858C6.21071 14.9609 6 15.4696 6 16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18C8.53043 18 9.03914 17.7893 9.41421 17.4142C9.78929 17.0391 10 16.5304 10 16ZM18 16C18 15.4696 17.7893 14.9609 17.4142 14.5858C17.0391 14.2107 16.5304 14 16 14C15.4696 14 14.9609 14.2107 14.5858 14.5858C14.2107 14.9609 14 15.4696 14 16C14 16.5304 14.2107 17.0391 14.5858 17.4142C14.9609 17.7893 15.4696 18 16 18C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16ZM24 18C24.5304 18 25.0391 17.7893 25.4142 17.4142C25.7893 17.0391 26 16.5304 26 16C26 15.4696 25.7893 14.9609 25.4142 14.5858C25.0391 14.2107 24.5304 14 24 14C23.4696 14 22.9609 14.2107 22.5858 14.5858C22.2107 14.9609 22 15.4696 22 16C22 16.5304 22.2107 17.0391 22.5858 17.4142C22.9609 17.7893 23.4696 18 24 18Z"
                  fill="url(#paint0_linear_1254_5729)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1254_5729"
                    x1="0"
                    y1="16.9313"
                    x2="32"
                    y2="16.9313"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#00C6FF" />
                    <stop offset="1" stopColor="#0072FF" />
                  </linearGradient>
                </defs>
              </svg>
              <span className="font-comfortaa text-azulfy-rich-black text-sm">
                {t("customer_support")}
              </span>
            </button>
            <span className="w-8 bg-grey-700 bg-opacity-50 h-[1px]" />
          </>
        </Permission>
        <button
          onClick={() => {
            navigate("/account");
            setOpenSettings(false);
          }}
          className="flex flex-col items-center gap-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M6 28C6 28 4 28 4 26C4 24 6 18 16 18C26 18 28 24 28 26C28 28 26 28 26 28H6ZM16 16C17.5913 16 19.1174 15.3679 20.2426 14.2426C21.3679 13.1174 22 11.5913 22 10C22 8.4087 21.3679 6.88258 20.2426 5.75736C19.1174 4.63214 17.5913 4 16 4C14.4087 4 12.8826 4.63214 11.7574 5.75736C10.6321 6.88258 10 8.4087 10 10C10 11.5913 10.6321 13.1174 11.7574 14.2426C12.8826 15.3679 14.4087 16 16 16Z"
              fill="url(#paint0_linear_1254_5750)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1254_5750"
                x1="4"
                y1="16"
                x2="28"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00C6FF" />
                <stop offset="1" stopColor="#0072FF" />
              </linearGradient>
            </defs>
          </svg>
          <span className="font-comfortaa text-azulfy-rich-black text-sm">
            {t("account")}
          </span>
        </button>
        <span className="w-8 bg-grey-700 bg-opacity-50 h-[1px]" />
        <button 
        onClick={() => {
          logout();
          setWorkspace({} as Workspace);
        }} 
        className="flex flex-col items-center gap-3"
        >
          <FiLogOut size={32} color={"#CD0C0C"} />
          <span className="font-comfortaa text-azulfy-rich-black text-sm">
            {t("logout_out")}
          </span>
        </button>
      </div>
    </div>
  );
}
