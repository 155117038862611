import * as Popover from "@radix-ui/react-popover";
import { IndicatorsProps } from "hooks/useFilters";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Permission, { useGetUserPermissions } from "utils/permission";

interface WhatPopoverProps {
  what: IndicatorsProps | null | undefined;
  setWhat: (what: IndicatorsProps | null | undefined) => void;
  whereSelected: IndicatorsProps[] | null | undefined; 
}
export default function WhatPopover({ what, setWhat, whereSelected }: WhatPopoverProps) {
  const { t } = useTranslation();
  const userPermissions = useGetUserPermissions();

  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Popover.Portal>
      <Popover.Content
        sideOffset={3}
        align="start"
        className="bg-white p-4 shadow-lg rounded-md border border-grey-400 border-opacity-30"
      >
        <Popover.Close ref={closeButton} hidden />
        <div className="flex flex-col w-full gap-3">
          <button
            onClick={() => {
              setWhat(undefined);
              closeButton.current?.click();
            }}
            className="text-start font-comfortaa max-md:text-xs text-sm hover:opacity-80"
          >
            <span>{t("all")}</span>
          </button>

          <Permission permissions={userPermissions}>
            <>
              {whereSelected && whereSelected
                .map((ind) => (
                  <button
                    key={ind.uuid}
                    onClick={() => {
                      setWhat(ind);
                      closeButton.current?.click();
                    }}
                    disabled={what?.id === ind.id}
                    className="text-start font-comfortaa max-md:text-xs text-sm hover:opacity-80"
                  >
                    <span>{ind?.name}</span>
                  </button>
                ))}
            </>
          </Permission>
        </div>
      </Popover.Content>
    </Popover.Portal>
  );
}
