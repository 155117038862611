/* eslint-disable react-hooks/rules-of-hooks */
import Header from "components/Header";
import { OptionsProps } from "components/ListMembers";
import { MapboxGeoJSONPolygon } from "components/MapComponent";
import { useUser } from "hooks/useUser";
import { useWorkspaces } from "hooks/useWorkspaces";
import {
  E164Number
} from 'libphonenumber-js/core';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "utils/toast";
import { GeographicalAreaMap } from "./GeographicalAreaMap";
import { PointsOfAnalysisMap } from "./PointsOfAnalysisMap";
import ProgressHeader from "./ProgressHeader";
import Step1 from "./Step1";
import Step4 from "./Step4";

export interface MembersProps {
  name: string;
  email: string;
  phone?: string;
  role: string | null;
  uuid: string;
}

export default function InviteRequest() {
  const { t } = useTranslation();
  const { authUser } = useUser();
  const { checkIfEmailExists, updateWorkspace } = useWorkspaces();
  const navigate = useNavigate();
  const [step, setStep] = useState(25);
  const [unableNext, setUnableNext] = useState(true);
  const [unabledInvite, setUnabledInvite] = useState(true);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [emailExistis, setEmailExistis] = useState(false);

  const [roleSelected, setRoleSelected] = useState<string | null>(null);

  const [numberOfMembersAdded, setNumberOfMembersAdded] = useState<number | null>(null);
  const [unableAddMoreMembers, setUnableAddMoreMembers] = useState(true);

  const [geoGraphicalArea, setGeoGraphicalArea] = useState<
    MapboxGeoJSONPolygon | undefined
  >();
  const [pointsOfAnalysisPolygons, setPointsOfAnalysisPolygons] = useState<
    MapboxGeoJSONPolygon[] | undefined
  >();

  const nameMemberRef = useRef<HTMLInputElement>(null);
  const emailMemberRef = useRef<HTMLInputElement>(null);
  const [phoneMember, setPhoneMember] = useState<E164Number>();

  const rolesOptions: OptionsProps[] = [
    { value: "admin", label: t("admin") },
    { value: "technicians", label: t("technicians") },
    {
      value: "external_service_provider",
      label: t("external_service_provider"),
    },
  ];

  useEffect(() => {
    const storedData = localStorage.getItem("new-request");
    if (!storedData) {
      return;
    }
    const localStorageData = JSON.parse(
      localStorage.getItem("new-request") || "{}"
    );
    const { members } = localStorageData;
    setNumberOfMembersAdded(members.length);
  });

  useEffect(() => {
    if(authUser?.workspaces?.license?.members === numberOfMembersAdded) {
      setUnableAddMoreMembers(true);
    } else {
      setUnableAddMoreMembers(false);
    }
  }, [authUser?.workspaces?.license?.members, numberOfMembersAdded]);

  function generateUniqueId() {
    return (
      Math.random().toString(36).substring(2) +
      new Date().getTime().toString(36)
    );
  }

  const [formDataNewCouncil, setFormDataNewCouncil] = useState({
    region_map_area: {} as JSON,
    region_map_area_points: [],
    members: [] as MembersProps[],
  });

  useEffect(() => {
    if (localStorage.getItem("new-request")) {
      localStorage.removeItem("new-request");
    }

    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = "";

      const confirmationMessage = t(
        "are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost"
      );
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const loadLocalStorageData = () => {
    const savedData = localStorage.getItem("new-request");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormDataNewCouncil(parsedData);
    }
  };

  useEffect(() => {
    loadLocalStorageData();
  }, []);

  useEffect(() => {
    if(step === 25) {
      setUnableNext(false);
    }
    if (step === 50) {
      if (geoGraphicalArea !== undefined) {
        setUnableNext(false);
      } else {
        setUnableNext(true);
      }
    }

    if (step === 75) {
      if (pointsOfAnalysisPolygons !== undefined && pointsOfAnalysisPolygons.length > 0) {
        setUnableNext(false);
      } else {
        setUnableNext(true);
      }
    }
  }, [step, geoGraphicalArea, pointsOfAnalysisPolygons]);

  useEffect(() => {
    if (
      nameMemberRef.current?.value !== "" &&
      emailMemberRef.current?.value !== "" &&
      roleSelected !== null
    ) {
      setUnabledInvite(false);
      // setUnableNext(false);
    } else {
      setUnabledInvite(true);
    }
  }, [
    nameMemberRef.current?.value,
    emailMemberRef.current?.value,
    roleSelected,
  ]);

  const handleNext = () => {
    if(step ===25){
      localStorage.setItem("new-request", JSON.stringify(formDataNewCouncil));
    }

    const value = Math.min(step + 25, 100);
    setStep(value);
  };

  const handlePrevious = () => {
    const value = Math.max(step - 25, 25);
    setStep(value);
  };

  function isEmailValid(email: string) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return emailRegex.test(email);
  }

  const handleInviteMember = async () => {
    setUnableNext(false);
    if (nameMemberRef.current && emailMemberRef.current) {
      const email = emailMemberRef.current.value;

      if (!isEmailValid(email)) {
        setEmailIsInvalid(true);
        return;
      } else {
        setEmailIsInvalid(false);
      }

      const emailExists = await checkIfEmailExists(email);

      if (!emailExists) {
        setEmailExistis(true);
      } else {
        setEmailExistis(false);

        const isEmailAlreadyExists =
          formDataNewCouncil.members.some((member) => member.email === email)

        if (isEmailAlreadyExists) {
          setEmailExistis(true);
          return;
        }

        const newMember = {
          name: nameMemberRef.current.value,
          email: email,
          phone: phoneMember,
          role: roleSelected || null,
          uuid: generateUniqueId(),
        };

        const localStorageData = JSON.parse(
          localStorage.getItem("new-request") || "{}"
        );
        const { code_azulfy, admin_email, admin_name, name, license_id } = localStorageData;

        const updatedMembers = [...formDataNewCouncil.members, newMember];

        const updatedFormData = {
          ...formDataNewCouncil,
          code_azulfy: code_azulfy,
          admin_email: admin_email,
          admin_name: admin_name,
          name: name,
          members: updatedMembers,
          license_id: license_id,
        };

        setFormDataNewCouncil(updatedFormData);

        localStorage.setItem("new-request", JSON.stringify(updatedFormData));
      }
    }

    if (
      nameMemberRef.current?.value &&
      emailMemberRef.current?.value &&
      phoneMember &&
      roleSelected
    ) {
      nameMemberRef.current.value = "";
      emailMemberRef.current.value = "";
      setPhoneMember(undefined);
      setRoleSelected(null);
    }
  };

  function getInitials(name: string | undefined): string {
    if (!name) return "";

    const trimmedName = name.trim();
    const nameParts = trimmedName.split(" ");
    let initials = "";

    for (let i = 0; i < nameParts.length && initials.length < 2; i++) {
      initials += nameParts[i][0].toUpperCase();
    }

    return initials;
  }

  const undoMember = (index: number) => {
    const updatedData = { ...formDataNewCouncil };
    updatedData.members.splice(index, 1);
    localStorage.setItem("new-request", JSON.stringify(updatedData));
    loadLocalStorageData();
  };

  const updateMemberRole = (uuid: string, newRole: string) => {
    const updatedData = { ...formDataNewCouncil };
    const memberUpdated = updatedData.members.find(
      (member) => member.uuid === uuid
    );
    if (!memberUpdated) return;
    const index = updatedData.members.indexOf(memberUpdated);
    updatedData.members[index!].role = newRole;
    localStorage.setItem("new-request", JSON.stringify(updatedData));
    loadLocalStorageData();
  };

  async function handleUpdateWorkspace() {
    try {
      const storedData = localStorage.getItem("new-request");
      if (!storedData) {
        throw new Error("Data not found in localStorage");
      }
      const newCouncilData = JSON.parse(storedData);
      const response = await updateWorkspace(authUser?.workspaces?.uuid!, newCouncilData);
      if (response) {
        toast({
          label: t("success"),
          message: t("request_sent_successfully"),
          type: "success",
        });
        navigate("/approval-process");
        return;
      }
      toast({
        label: t("error"),
        message: t("an_error_occurred_while_creating_the_council"),
        type: "error",
      });
    } catch (error) {
      toast({
        label: t("error"),
        message: t("an_error_occurred_while_creating_the_council"),
        type: "error",
      });
    }
  }

  const handlePhoneMember = (value: E164Number | undefined) => {
    setPhoneMember(value)
  }

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem('new-request');

    if (dataFromLocalStorage) {
      const localStorageData = JSON.parse(dataFromLocalStorage);

      const updatedData = {
        ...localStorageData,
        region_map_area: JSON.stringify(geoGraphicalArea),
      };

      localStorage.setItem('new-request', JSON.stringify(updatedData));
    }
  }, [geoGraphicalArea]);

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem('new-request');

    if (dataFromLocalStorage) {
      const localStorageData = JSON.parse(dataFromLocalStorage);

      if (pointsOfAnalysisPolygons) {
        const mappedPoints = pointsOfAnalysisPolygons.map(point => ({
          name: point.properties.name,
          json: JSON.stringify(point)
        }));

        const updatedData = {
          ...localStorageData,
          region_map_area_points: mappedPoints
        };

        localStorage.setItem('new-request', JSON.stringify(updatedData));
      }
    }
  }, [pointsOfAnalysisPolygons]);

  return (
    <>
      <Header />
      <div className="w-full mx-auto max-w-5xl">
        <ProgressHeader
          step={step}
          unableNext={unableNext}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          handleUpdateWorkspace={handleUpdateWorkspace}
        />
        <main className="w-full">
          {step === 25 && (
            <Step1
              formDataNewCouncil={formDataNewCouncil}
              emailAdmin={authUser?.workspaces.admin_email || ""}
              emailExistis={emailExistis}
              emailIsInvalid={emailIsInvalid}
              emailMemberRef={emailMemberRef}
              phoneMember={phoneMember}
              handlePhoneMember={handlePhoneMember}
              getInitials={getInitials}
              handleInviteMember={handleInviteMember}
              nameAdmin={authUser?.workspaces.admin_name || ""}
              nameMemberRef={nameMemberRef}
              rolesOptions={rolesOptions}
              roleSelected={roleSelected}
              setUnabledInvite={setUnabledInvite}
              setRoleSelected={setRoleSelected}
              updateMemberRole={updateMemberRole}
              undoMember={undoMember}
              unabledInvite={unabledInvite}
              numberOfMembersAdded={numberOfMembersAdded! + authUser?.workspaces.total_users! || 0}
              unableAddMoreMembers={unableAddMoreMembers}
              limitOfMembers={authUser?.workspaces?.license?.members! ?? 0}
            />
          )}
          {step === 50 && (
            <>
              <div className="flex flex-col w-full gap-5">
                <p className="text-2xl font-bold text-center text-azulfy-blue font-comfortaa">
                  {t("define_the_points_of_analysis")}
                </p>
                <div>
                  <GeographicalAreaMap
                    geoGraphicalArea={geoGraphicalArea}
                    setGeoGraphicalArea={setGeoGraphicalArea}
                  />
                </div>
              </div>
            </>
          )}
          {step === 75 && (
            <>
              <div className="flex flex-col w-full gap-5">
                <p className="text-2xl font-bold text-center text-azulfy-blue font-comfortaa">
                  {t("define_the_points_of_analysis")}
                </p>
                <div>
                  <PointsOfAnalysisMap
                    geoArea={geoGraphicalArea}
                    pointsOfAnalysisPolygons={pointsOfAnalysisPolygons}
                    setPointsOfAnalysisPolygons={setPointsOfAnalysisPolygons}
                  />
                </div>
              </div>
            </>
          )}
          {step === 100 && (
            <Step4
              emailAdmin={authUser?.user?.email || ""}
              nameAdmin={authUser?.user?.name || ""}
              formDataNewCouncil={formDataNewCouncil}
              getInitials={getInitials}
              rolesOptions={rolesOptions}
              updateMemberRole={updateMemberRole}
            />
          )}
        </main>
      </div>
    </>
  );
}
