import * as Popover from "@radix-ui/react-popover";
import { FilterProps, useFilters } from "hooks/useFilters";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Permission, { useGetUserPermissions } from "utils/permission";
interface WherePopoverProps {
  where: FilterProps | null | undefined;
  setWhere: (where: FilterProps | null | undefined) => void;
}

export default function WherePopover({ where, setWhere }: WherePopoverProps) {
  const { t } = useTranslation();
  const userPermissions = useGetUserPermissions();
  const { filters } = useFilters();

  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Popover.Portal>
      <Popover.Content
        sideOffset={3}
        align="start"
        className="bg-white p-4 shadow-lg rounded-md border border-grey-400 border-opacity-30"
      >
        <Popover.Close ref={closeButton} hidden />
        <div className="flex flex-col w-full gap-3">
          <button
            onClick={() => {
              setWhere(undefined);
              closeButton.current?.click();
            }}
            className="text-start font-comfortaa max-md:text-xs text-sm hover:opacity-80"
          >
            <span>{t("all")}</span>
          </button>
          <Permission permissions={userPermissions}>
            <>
              {filters
                .filter((type) => userPermissions.includes(type.name))
                .map((type) => (
                  <button
                    key={type.id}
                    onClick={() => {
                      setWhere(type);
                      closeButton.current?.click();
                    }}
                    disabled={where?.id === type.id}
                    className="text-start font-comfortaa max-md:text-xs text-sm hover:opacity-80"
                  >
                    <span>{t(type?.name.toLowerCase())}</span>
                  </button>
                ))}
            </>
          </Permission>
        </div>
      </Popover.Content>
    </Popover.Portal>
  );
}
