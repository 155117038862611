import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as Dialog from "@radix-ui/react-dialog";
import * as Popover from "@radix-ui/react-popover";
import GenericModal from "components/GenericModal";
import Map, { MapboxGeoJSONPolygon } from "components/MapComponent";
import GeoInputRow from "components/MapComponent/internals/GeoInputRow";
import DeleteAllPopOver from "components/MapComponent/internals/addOns/components/DeleteAllPopOver";
import MapBasicButtons from "components/MapComponent/internals/addOns/components/MapBasicButtons";
import MapNotification from "components/MapComponent/internals/addOns/components/MapNotification";
import { defaultDrawStyles } from "components/MapComponent/internals/defaultDrawStyles";
import { polygonBuilder } from "components/MapComponent/internals/helpers";
import { t } from "i18next";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  BsCheckLg,
  BsFillPencilFill,
  BsThreeDots,
  BsTrashFill,
  BsX,
} from "react-icons/bs";
// import MapNotificationError from "components/MapComponent/internals/addOns/components/MapNotification/MapNotificationError";
import { toast } from "utils/toast";

interface IGeographicalAreaMapProps {
  geoArea: MapboxGeoJSONPolygon | undefined;
  pointsOfAnalysisPolygons: MapboxGeoJSONPolygon[] | undefined;
  setPointsOfAnalysisPolygons: Dispatch<
    SetStateAction<MapboxGeoJSONPolygon[] | undefined>
  >;
}

export const PointsOfAnalysisMap = ({
  geoArea,
  pointsOfAnalysisPolygons,
  setPointsOfAnalysisPolygons,
}: IGeographicalAreaMapProps) => {
  const [createNewPointOfAnalysisProps, setCreateNewPointOfAnalysisProps] =
    useState<{
      showModal: boolean;
      nameInputValue: string;
    }>({
      showModal: false,
      nameInputValue: "",
    });

  const [showNotification, setShowNotification] = useState(false);
  // const [showNotificationError, setShowNotificationError] = useState(false);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  const onUpdatePolygon = (feature: MapboxGeoJSONPolygon) => {
    if (!pointsOfAnalysisPolygons) return;
    const requestedIndex = pointsOfAnalysisPolygons?.findIndex(
      (polygon) => polygon.id === feature.id
    );
    if (requestedIndex === -1) return;

    const previousValue = pointsOfAnalysisPolygons;
    previousValue[requestedIndex].geometry.coordinates =
      feature.geometry.coordinates;

    setPointsOfAnalysisPolygons([...previousValue]);
  };

  const isPointInsidePolygon = (point: [number, number], polygon: any) => {
    const x = point[0];
    const y = point[1];
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0];
      const yi = polygon[i][1];
      const xj = polygon[j][0];
      const yj = polygon[j][1];

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }

    return inside;
  };

  const [
    tempCreateNewPointOfAnalysisProps,
    setTempCreateNewPointOfAnalysisProps,
  ] = useState<MapboxGeoJSONPolygon | undefined>();

  const onAddPolygon = (feature: MapboxGeoJSONPolygon) => {
    setTempCreateNewPointOfAnalysisProps(feature);
    setCreateNewPointOfAnalysisProps({
      nameInputValue: "",
      showModal: true,
    });
  };

  const confirmPolygonCreation = () => {
    if (!tempCreateNewPointOfAnalysisProps) return;

    const newPoint: MapboxGeoJSONPolygon = {
      ...tempCreateNewPointOfAnalysisProps,
      properties: {
        name: createNewPointOfAnalysisProps.nameInputValue,
        type: "PointsOfAnalysis",
      },
    }

    setPointsOfAnalysisPolygons((prev) => [
      ...(prev ?? []),
      newPoint,
    ]);

    const existingFeatures = drawRef.current?.getAll().features;

    mapRef.current?.removeControl(drawRef.current as unknown as mapboxgl.Control);

    const letters = [...(pointsOfAnalysisPolygons ?? []), newPoint].map((item, index) => ({
      id: `custom-point-${index}`,
      filter: ["==", "id", item.id],
      type: "symbol",
      layout: {
        "text-field": String.fromCharCode(65 + index),
        "text-size": 16,
        "text-anchor": "center",
        "text-justify": "auto",
      },
      paint: {
        "text-color": "#0072FF",
      },
    }))

    const newDraw = new MapboxDraw({
      displayControlsDefault: false,
      styles: [...defaultDrawStyles, ...letters!]
    });

    mapRef.current?.addControl(newDraw);

    existingFeatures?.forEach(feature => newDraw.add(feature));

    drawRef.current = newDraw

    setTempCreateNewPointOfAnalysisProps(undefined);
    setCreateNewPointOfAnalysisProps({
      nameInputValue: "",
      showModal: false,
    });
  };

  useEffect(() => {
    if (!mapRef.current || !drawRef.current) return;

    mapRef.current.on("load", () => {
      if (!geoArea) return;
      const geoAreaPointsFeatures = geoArea.geometry.coordinates[0].map(
        (point) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: point,
          },
        })
      );

      mapRef.current?.addSource("national-park", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: geoArea.geometry.coordinates,
              },
            } as never,
            ...(geoAreaPointsFeatures as never),
          ],
        },
      });

      mapRef.current?.addLayer({
        id: "park-boundary",
        type: "fill",
        source: "national-park",
        paint: {
          "fill-color": "#888888",
          "fill-opacity": 0,
        },
        filter: ["==", "$type", "Polygon"],
      });

      mapRef.current?.addLayer({
        id: "outline",
        type: "line",
        source: "national-park",
        layout: {},
        paint: {
          "line-color": "#0072FF",
          "line-width": 2,
        },
      });

      mapRef.current?.addLayer({
        id: "park-volcanoes",
        type: "circle",
        source: "national-park",
        paint: {
          "circle-radius": 8,
          "circle-color": "#0072FF",
        },
        filter: ["==", "$type", "Point"],
      });

      if (pointsOfAnalysisPolygons) {
        //foreach polygon in pointsOfAnalysisPolygons enable draw 
        pointsOfAnalysisPolygons.forEach((polygon) => {
          drawRef.current?.add({
            type: "FeatureCollection",
            features: [{
              ...polygon,
              id: polygon.id
            }] as never,

          });
        })
      }

    });

    // Desabilita a capacidade de desenhar fora da área do geoArea
    mapRef.current.on("draw.modechange", (event) => {
      if (!geoArea) return;

      if (event.mode === "draw_polygon") {
        drawRef.current?.changeMode("simple_select");
        // setShowNotificationError(true); // Mostra notificação que desenho fora da área não é permitido
      }
    });
  }, [pointsOfAnalysisPolygons, mapRef, drawRef, geoArea]);

  useEffect(() => {
    if (!mapRef.current || !drawRef.current) return;

    let alertTimeout: NodeJS.Timeout | null = null;

    const handleDrawCreate = (event: any) => {

      if (!geoArea || event.features[0].geometry.type !== "Polygon") return;

      const newPolygonCoordinates = event.features[0].geometry.coordinates[0];
      const isInsideGeoArea = newPolygonCoordinates.every((point: [number, number]) =>
        isPointInsidePolygon(point, geoArea.geometry.coordinates[0])
      );

      if (!isInsideGeoArea) {
        toast({
          label: t('error'),
          message: t('make_the_polygons_only_within_the_area_marked_on_the_map'),
          type: "error",
        })
        drawRef.current?.delete(event.features[0].id);
        return;
      }

      const coordinates = event.features[0].geometry.coordinates[0];

      if (coordinates.length !== 5) {
        if (!alertTimeout) {
          console.log("draw a square or rectangle with 4 points");
          toast({
            label: t('error'),
            message: t('draw_a_square_or_rectangle_with_4_points'),
            type: "error",
          })
          alertTimeout = setTimeout(() => {
            alertTimeout = null;
          }, 100);
        }

        drawRef.current?.delete(event.features[0].id);
        return;
      }

      onAddPolygon?.(event.features[0]);
    };

    mapRef.current.on("draw.create", handleDrawCreate);

    return () => {
      mapRef.current?.off("draw.create", handleDrawCreate);
      if (alertTimeout) clearTimeout(alertTimeout);
    };
  }, []);

  useEffect(() => {
    mapRef.current?.removeControl(drawRef.current as unknown as mapboxgl.Control);

    const letters = pointsOfAnalysisPolygons?.map((item, index) => ({
      id: `custom-point-${index}`,
      filter: ["==", "id", item.id],
      type: "symbol",
      layout: {
        "text-field": String.fromCharCode(65 + index),
        "text-size": 16,
        "text-anchor": "center",
        "text-justify": "auto",
      },
      paint: {
        "text-color": "#0072FF",
      },
    }))

    const newDraw = new MapboxDraw({
      displayControlsDefault: false,
      styles: [...defaultDrawStyles, ...letters!]
    });

    mapRef.current?.addControl(newDraw);

    drawRef.current = newDraw
  }, []);

  useEffect(() => {
    if (!mapRef.current || !drawRef.current) return;

    mapRef.current?.on("draw.update", (event) => {
      // To enable Point or Linestring change this rule
      if (event.features[0].geometry.type !== "Polygon") return;

      onUpdatePolygon?.(event.features[0]);
    });

    mapRef.current?.on("draw.delete", (event) => {
      if (event.features[0].geometry.type !== "Polygon") return;

      onDeletePolygon?.(event.features[0]);
    });
  }, [pointsOfAnalysisPolygons, mapRef, drawRef]);

  useEffect(() => {
    if (mapRef.current && geoArea && geoArea.geometry) {
      const coordinates = geoArea.geometry.coordinates[0];
      const center = [
        coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length,
        coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length,
      ];
      const zoom = 10;

      mapRef.current.setCenter(center as LngLatLike);
      mapRef.current.setZoom(zoom);
    }
  }, [geoArea, mapRef]);

  const onDeletePolygon = (feature: MapboxGeoJSONPolygon) => {
    if (!pointsOfAnalysisPolygons) return;

    setPointsOfAnalysisPolygons(
      pointsOfAnalysisPolygons.filter((polygon) => polygon.id !== feature.id)
    );
  };

  const onConfirmRowDeleteRowCoordinate = (rowIndex: number, index: number) => {
    const selectedPolygon = pointsOfAnalysisPolygons?.[rowIndex];

    // Start and end have points, so count one more point than index
    if (
      selectedPolygon &&
      selectedPolygon?.geometry?.coordinates?.[0]?.length &&
      selectedPolygon?.geometry?.coordinates?.[0]?.length !== 4
    ) {
      setPointsOfAnalysisPolygons(
        pointsOfAnalysisPolygons.filter((_, i) => i !== rowIndex)
      );
      drawRef.current?.delete(selectedPolygon.id);
      return;
    }
    if (
      selectedPolygon &&
      selectedPolygon?.geometry?.coordinates?.[0]?.[index]
    ) {
      const previousValue = pointsOfAnalysisPolygons[rowIndex];
      previousValue.geometry.coordinates[0].splice(index, 1);
      setPointsOfAnalysisPolygons(
        pointsOfAnalysisPolygons.map((polygon, i) => {
          if (i === rowIndex) {
            return {
              ...previousValue,
            };
          }
          return polygon;
        })
      );

      drawRef.current?.delete(previousValue.id);
      drawRef.current?.set(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        polygonBuilder({
          id: previousValue.id,
          coordinates: previousValue.geometry.coordinates,
          properties: previousValue.properties,
        })
      );
    }
  };

  const onConfirmRowCoordinateUpdate = (
    rowIndex: number,
    coordinateIndex: number,
    updatedCoordinate: number[]
  ) => {
    const previousValue = pointsOfAnalysisPolygons?.[rowIndex];
    if (previousValue?.geometry?.coordinates?.[0]?.[coordinateIndex]) {
      previousValue.geometry.coordinates[0][coordinateIndex] =
        updatedCoordinate;

      previousValue.geometry.coordinates[0][
        previousValue.geometry.coordinates[0].length - 1
      ] = updatedCoordinate;

      setPointsOfAnalysisPolygons(
        pointsOfAnalysisPolygons?.map((polygon, i) => {
          if (i === rowIndex) {
            return {
              ...previousValue,
            };
          }

          return polygon;
        })
      );
      drawRef.current?.delete(previousValue.id);
      drawRef.current?.set(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        polygonBuilder({
          id: previousValue.id,
          coordinates: previousValue.geometry.coordinates,
          properties: previousValue.properties,
        })
      );
    }
  };

  const onConfirmNameUpdate = (editIndex: number, name: string) => {
    setPointsOfAnalysisPolygons(
      pointsOfAnalysisPolygons?.map((polygon, index) => {
        if (index === editIndex) {
          return {
            ...polygon,
            properties: {
              ...polygon.properties,
              name: name,
            },
          };
        }
        return polygon;
      })
    );
  };

  const onCloseModal = () => {
    if (tempCreateNewPointOfAnalysisProps) {
      drawRef.current?.delete(tempCreateNewPointOfAnalysisProps.id);
      setTempCreateNewPointOfAnalysisProps(undefined);
    }
    setCreateNewPointOfAnalysisProps({
      ...createNewPointOfAnalysisProps,
      showModal: false,
    });
  };

  return (
    <div className="mapWrapper">
      <Map
        showNotification={showNotification}
        mapRef={mapRef}
        drawRef={drawRef}
      >
        <MapBasicButtons canDraw={true} drawRef={drawRef} mapRef={mapRef} />
        <MapNotification
          setShowNotification={setShowNotification}
          showNotification={showNotification}
        />
        {/* <MapNotificationError
          setShowNotification={setShowNotificationError}
          showNotification={showNotificationError}
        /> */}
      </Map>
      <Dialog.Root open={createNewPointOfAnalysisProps.showModal}>
        <GenericModal
          title={t('creating_region')}
          description={t('please_type_a_name_for_the_created_region')}
          handleConfirm={confirmPolygonCreation}
          handleCancel={onCloseModal}
        >
          <input
            value={createNewPointOfAnalysisProps.nameInputValue}
            onChange={(e) => {
              setCreateNewPointOfAnalysisProps({
                ...createNewPointOfAnalysisProps,
                nameInputValue: e.target.value,
              });
            }}
            type="text"
            placeholder={t('name')}
            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
          />
        </GenericModal>

      </Dialog.Root>
      <div className="py-12">
        <span className="text-xl font-bold text-azulfy-blue">
          {t("gps_coordinates")}
        </span>

        <div className="flex justify-between">
          <p className="text-azulfy-rich-black">
            {t("click_on_the_map_to_choose_the_location_for_analysis")}
          </p>
          {pointsOfAnalysisPolygons && pointsOfAnalysisPolygons.length > 0 && (
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="hover:opacity-70">
                  <BsThreeDots size={24} color="#0072FF" />
                </button>
              </Popover.Trigger>
              <DeleteAllPopOver
                onConfirmDeleteAll={() => {
                  setPointsOfAnalysisPolygons(undefined);
                  drawRef.current?.deleteAll();
                  if (drawRef.current && pointsOfAnalysisPolygons) {
                    pointsOfAnalysisPolygons.forEach(polygon => {
                      drawRef.current?.delete(polygon.id);

                      // mapRef.current?.removeLayer(polygon.id);

                      // mapRef.current?.removeSource(polygon.id);
                    });

                  }
                }}
              />
            </Popover.Root>
          )}
        </div>

        <ul className="flex flex-col flex-1 w-full gap-12 m-0 list-none ">
          {pointsOfAnalysisPolygons?.map((polygon, index) => {
            return (
              <PointOfAnalysisRow
                key={index}
                index={index}
                polygon={polygon}
                onConfirmNameUpdate={onConfirmNameUpdate}
                onConfirmRowDeleteRowCoordinate={
                  onConfirmRowDeleteRowCoordinate
                }
                onConfirmRowCoordinateUpdate={onConfirmRowCoordinateUpdate}
                pointsOfAnalysisPolygons={pointsOfAnalysisPolygons} // Passando pointsOfAnalysisPolygons como uma prop
                onDeletePolygon={onDeletePolygon}
                mapRef={mapRef}
                drawRef={drawRef}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const PointOfAnalysisRow = ({
  index,
  polygon,
  onConfirmNameUpdate,
  onConfirmRowDeleteRowCoordinate,
  onConfirmRowCoordinateUpdate,
  pointsOfAnalysisPolygons,
  onDeletePolygon,
  mapRef,
  drawRef,
}: {
  index: number;
  polygon: MapboxGeoJSONPolygon;
  onConfirmNameUpdate: (index: number, name: string) => void;
  onConfirmRowDeleteRowCoordinate: (rowIndex: number, index: number) => void;
  onConfirmRowCoordinateUpdate: (
    rowIndex: number,
    coordinateIndex: number,
    coordinate: number[]
  ) => void;
  pointsOfAnalysisPolygons: MapboxGeoJSONPolygon[] | undefined;
  onDeletePolygon: (polygon: MapboxGeoJSONPolygon) => void;
  mapRef: React.RefObject<mapboxgl.Map>;
  drawRef: React.RefObject<MapboxDraw>;
}) => {
  const [editNameInput, setEditNameInput] = useState(
    polygon?.properties?.name ?? ""
  );
  const [editOption, setEditOption] = useState<undefined | "edit" | "delete">(
    undefined
  );

  useEffect(() => {
    setEditNameInput(polygon?.properties?.name ?? "");
  }, [polygon.properties.name]);

  return (
    <div className="flex flex-col md:flex-row items-start gap-5">
      <div className="flex flex-col flex-1">
        {index === 0 && (
          <div className="flex-1 mb-3">
            <span className="text-base font-bold text-azulfy-blue">{t('name')}</span>
          </div>
        )}
        <div className="flex items-center">
          <div className="flex items-end flex-1 gap-3">
            <span className="pb-2.5 text-base font-bold text-azulfy-blue">
              {String.fromCharCode(65 + index)}
            </span>

            <input
              disabled={!(editOption === "edit")}
              value={editNameInput}
              onChange={(e) => {
                setEditNameInput(e.target.value);
              }}
              type="text"
              placeholder="Ex.: Camera Oeiras"
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
            />
          </div>

          {editOption ? (
            <div className="flex items-center gap-5 ml-3">
              <button
                onClick={() => {
                  setEditOption(undefined);
                  setEditNameInput(polygon?.properties?.name ?? "");
                }}
                title={
                  editOption === "edit" ? t("Cancel edit") : t("Cancel delete")
                }
                className="w-6 h-6 grid place-items-center shadow-lg rounded ring-[0.5px] ring-grey-400"
              >
                <BsX className="w-5 h-5 text-error" />
              </button>
              <button
                title={
                  editOption === "edit"
                    ? t("Confirm edit")
                    : t("Confirm delete")
                }
                className="w-6 h-6 grid place-items-center shadow-lg rounded ring-[0.5px] ring-grey-400"
                onClick={() => {
                  if (editOption === "edit") {
                    onConfirmNameUpdate(index, editNameInput);
                  }
                  if (editOption === "delete") {
                    // onConfirmDelete(index);
                    const polygonToDelete = pointsOfAnalysisPolygons![index]; // Obtém o polígono que será deletado
                    if (polygonToDelete) {
                      onDeletePolygon(polygonToDelete); // Chama a função para deletar o polígono
                    }

                    drawRef.current?.delete(polygonToDelete.id);

                    // Remover a camada do polígono do mapa
                    mapRef.current?.removeLayer(polygonToDelete.id);
                    // Se necessário, remova também a fonte do polígono
                    mapRef.current?.removeSource(polygonToDelete.id);
                  }
                  setEditOption(undefined);
                }}
              >
                <BsCheckLg className="w-4 h-4 text-success" />
              </button>
            </div>
          ) : (
            <div className="flex items-center gap-5 ml-3">
              <button title={t("edit")} onClick={() => setEditOption("edit")}>
                <BsFillPencilFill className="text-[#C0C4C8] md:w-6 w-4 md:h-6 h-4" />
              </button>
              <button
                title={t("delete")}
                onClick={() => setEditOption("delete")}
              >
                <BsTrashFill className="text-[#C0C4C8] md:w-6 w-4 md:h-6 h-4" />
              </button>
            </div>
          )}
        </div>
      </div>

      <ul className="flex flex-col flex-1 gap-4 p-0 m-0 list-none">
        {polygon?.geometry.coordinates[0]
          .slice(0, polygon?.geometry.coordinates[0].length - 1)
          .map((coordinate, coordsIndex) => {
            return (
              <li key={coordsIndex} className="flex flex-col">
                {index === 0 && coordsIndex === 0 && (
                  <div className="flex mb-3">
                    <div className="flex-1">
                      <span className="text-base font-bold text-azulfy-blue">
                        Latitude
                      </span>
                    </div>
                    <div className="flex-1">
                      <span className="text-base font-bold text-azulfy-blue">
                        Longitude
                      </span>
                    </div>
                  </div>
                )}
                <GeoInputRow
                  index={coordsIndex}
                  indexNumber={coordsIndex + 1}
                  coordinate={coordinate}
                  onConfirmUpdate={(coordinateIndex, coordinate) => {
                    onConfirmRowCoordinateUpdate(
                      index,
                      coordinateIndex,
                      coordinate
                    );
                  }}
                  onConfirmDelete={(coordinateIndex) => {
                    onConfirmRowDeleteRowCoordinate(index, coordinateIndex);
                  }}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};
