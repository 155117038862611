import * as Dialog from "@radix-ui/react-dialog";
import ConfirmDeleteMemberModal from "components/ConfirmDeleteMemberModal";
import EditMemberModal, { EditMemberType } from "components/EditMemberModal";
import { LinearButton } from "components/LinearButton";
import Loading from "components/Loading";
import { useUser } from "hooks/useUser";
import { useWorkspaces } from "hooks/useWorkspaces";
import {
    E164Number
} from 'libphonenumber-js/core';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft, BsThreeDotsVertical } from "react-icons/bs";
import PhoneInput from 'react-phone-number-input';
import { Link, useParams } from "react-router-dom";
import { Popover, SelectPicker, Whisper } from 'rsuite';
import { classNames } from "utils";
import Permission from "utils/permission";
import { toast } from "utils/toast";

export interface OptionsProps {
    value: string;
    label: string;
}

interface ListMembersProps {
    showButtonAddMembers: boolean
    alignLabel?: boolean
}

export default function index({ showButtonAddMembers, alignLabel = true }: ListMembersProps) {
    const { t } = useTranslation();
    const { authUser, updateUser } = useUser();
    const { getAllMembersOfWorkspace, memberWorkspace, deleteMemberOfWorkspace, updateRoleMemberOfWorkspace, createNewMemberOfWorkspace, getWorkspacesByUuid, editMemberOfWorkspace, workspace } = useWorkspaces();
    const [isLoading, setIsLoading] = useState(true);
    const [disabledButtonInvite, setDisabledButtonInvite] = useState(true);
    const [showAddMember, setShowAddMember] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState<Record<string, string>>({});
    const { uuid } = useParams<{ uuid: string }>()
    const rolesOptions: OptionsProps[] = [
        { value: 'admin', label: t('admin') },
        { value: 'technicians', label: t('technicians') },
        { value: 'external_service_provider', label: t('external_service_provider') },
    ];

    const [phoneMember, setPhoneMember] = useState<E164Number>();

    // const nameMemberInviteRef = useRef<HTMLInputElement>(null);
    // const emailMemberInviteRef = useRef<HTMLInputElement>(null);
    const [nameMemberInvite, setNameMemberInvite] = useState<string>("");
    const [emailMemberInvite, setEmailMemberInvite] = useState<string>("");
    const [roleSelectedInviteMember, setRoleSelectedInviteMember] = useState<string>("");

    const closeButton = useRef<HTMLButtonElement>(null);

    const hasPermissionEditMembers = Permission({
        permissions: ['edit members workspace'],
        children: <></>,
    });

    async function handleGetAllMembersOfWorkspace() {
        await getAllMembersOfWorkspace(uuid!, "?sortBy=created_at&sortDirection=desc&page=1&take=40")
        setIsLoading(false);
    }

    useEffect(() => {
        handleGetAllMembersOfWorkspace();
    }, [uuid]);

    async function handleDeleteMember(uuidMember: string) {
        await deleteMemberOfWorkspace(uuid!, uuidMember)
            .then(() => {
                closeButton.current?.click();
                getWorkspace()
                handleGetAllMembersOfWorkspace()
                toast({
                    label: t('success'),
                    message: t('user_deleted_successfully'),
                    type: "success",
                })
            })
            .catch((error) => {
                toast({
                    label: t('error'),
                    message: error.response.data.content.message || t('error_deleting_user'),
                    type: "error",
                })
            })
    }

    const getWorkspace = async () => {
        await getWorkspacesByUuid(uuid!)
        setIsLoading(false);
    }

    useEffect(() => {
        getWorkspace()
    }, [uuid]);

    useEffect(() => {
        const newSelectedRoles: Record<string, string> = {};
        memberWorkspace?.admin?.forEach((adminMember) => {
            newSelectedRoles[adminMember.user?.uuid!] = adminMember.role_name;
        });
        memberWorkspace?.technicians?.forEach((technicianMember) => {
            newSelectedRoles[technicianMember.user?.uuid!] = technicianMember.role_name;
        });
        memberWorkspace?.external_service_provider?.forEach((externalMember) => {
            newSelectedRoles[externalMember.user?.uuid!] = externalMember.role_name;
        });
        setSelectedRoles(newSelectedRoles);
    }, [memberWorkspace]);

    async function handleUpdateRole(uuidMember: string, newRole: string) {
        await updateRoleMemberOfWorkspace(uuid!, uuidMember, { role: newRole })
            .then(() => {
                handleGetAllMembersOfWorkspace()
                toast({
                    label: t('success'),
                    message: t('role_updated_successfully'),
                    type: "success",
                })
            })
            .catch((error) => {
                toast({
                    label: t('error'),
                    message: error.response.data.content.message || t('error_updating_role'),
                    type: "error",
                })
            });
    }

    async function handleEditMember(uuidMember: string, data: EditMemberType, close: () => void) {
        await editMemberOfWorkspace(uuidMember, data)
            .then(() => {
                close()
                handleGetAllMembersOfWorkspace()
                toast({
                    label: t('success'),
                    message: t('member_edited_successfully'),
                    type: "success",
                })
                if(authUser?.user?.uuid === uuidMember){
                    updateUser({ name: data.name, phone: data.phone as string })
                }
            })
            .catch((error: unknown) => {
                console.error('Error editing member:', error);
            });
    }

    useEffect(() => {
        if (nameMemberInvite !== "" && emailMemberInvite !== "" && roleSelectedInviteMember !== "") {
            setDisabledButtonInvite(false);
        } else {
            setDisabledButtonInvite(true);
        }
    }, [nameMemberInvite, emailMemberInvite, roleSelectedInviteMember]);

    async function handleCreateNewMember() {
        setDisabledButtonInvite(true);

        await createNewMemberOfWorkspace(uuid!, { member_name: nameMemberInvite, member_email: emailMemberInvite, role: roleSelectedInviteMember, member_phone: phoneMember })
            .then(() => {
                getWorkspace()
                handleGetAllMembersOfWorkspace();
                setShowAddMember(false);
                setDisabledButtonInvite(true);
                setNameMemberInvite("");
                setEmailMemberInvite("");
                setPhoneMember(undefined);
                toast({
                    label: t('success'),
                    message: t('invitation_sent_successfully'),
                    type: "success",
                })
            })
            .catch((error) => {
                console.error('Error creating new member:', error);
            });
    }

    return (
        <>
            {showButtonAddMembers && (
                <header
                    className="flex items-center w-full justify-between py-1 lg:mb-5 capitalize"
                >
                    <div className="flex items-center gap-2">
                        <Link to={`/workspace/${uuid}`} className={`hidden md:block`}>
                            <BsChevronLeft size={20} className="text-azulfy-blue" />
                        </Link>
                        <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                            {t('members')}
                        </p>
                    </div>
                    <p className="text-[#808080] text-sm font-normal font-comfortaa">
                        {workspace?.total_users}/{workspace?.license?.members}
                    </p>
                </header>
            )}

            <div className="flex flex-col gap-3 font-comfortaa w-full max-w-5xl mx-auto">
                {showButtonAddMembers && (
                    <>
                        {showAddMember ? (
                            <>
                                {workspace?.total_users === workspace?.license?.members ? (
                                    <>
                                        <p className="text-azulfy-blue font-comfortaa text-xl font-bold text-center">
                                            {t("maximum_members_reached")}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <div className="w-full flex flex-col items-center justify-center gap-3 font-comfortaa">
                                            <h3 className="text-2xl font-bold text-azulfy-blue">
                                                {t('invite_those_who_will_work_on_this_council')}
                                            </h3>
                                            <div className="w-full max-w-lg mx-auto mt-2 flex flex-col gap-4">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    onChange={(value) => {
                                                        setNameMemberInvite(value.target.value);
                                                    }}
                                                    placeholder={t('name')}
                                                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none"
                                                />
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    onChange={(value) => {
                                                        setEmailMemberInvite(value.target.value);
                                                    }}
                                                    placeholder={t('email')}
                                                    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none"
                                                />
                                                <SelectPicker
                                                    data={rolesOptions}
                                                    searchable={false}
                                                    style={{ width: '100%', fontSize: '14px', color: '#061425' }}
                                                    cleanable={false}
                                                    size="lg"
                                                    placeholder={t('select_a_role')}
                                                    className="text-azulfy-rich-black"
                                                    onSelect={(value) => {
                                                        setRoleSelectedInviteMember(value);
                                                    }}
                                                />

                                                <PhoneInput
                                                    type="tel"
                                                    name="phone"
                                                    id="phone"
                                                    value={phoneMember}
                                                    onChange={(value) => {
                                                        setPhoneMember(value);
                                                    }}
                                                    placeholder={t('phone_number')}
                                                    className="w-full border border-gray-300 rounded-lg px-4 py-2 [&_*:focus]:outline-none [&_*]:text-base"
                                                />
                                                <LinearButton disabled={disabledButtonInvite} text={t('invite')} textSize="16" onClick={handleCreateNewMember} />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </>
                        ) : (
                            <Permission permissions={['create members workspace']}>
                                <div className="md:w-60 w-full my-5">
                                    <LinearButton text={`+ ${t('add_members')}`} textSize="16" onClick={() => setShowAddMember(true)} />
                                </div>
                            </Permission>
                        )}
                    </>
                )}

                {memberWorkspace?.technicians?.length > 0 && memberWorkspace?.external_service_provider?.length > 0 && memberWorkspace?.admin?.length > 0 && (
                    <div className="flex items-center justify-between font-comfortaa">
                        <p className="text-base font-bold text-gray-500">
                            {t('name')}
                        </p>

                        <p className={classNames(alignLabel ? 'md:mr-[480px] md:translate-x-[100%]' : '', "text-base font-bold text-gray-500 mt-0")}>
                            {t('role')}
                        </p>
                    </div>
                )}

                {isLoading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <div className="flex flex-col gap-12 font-comfortaa">
                            <div>
                                {memberWorkspace?.admin?.length > 0 && (
                                    <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                        {t('admin')}
                                    </p>
                                )}
                                {React.Children.toArray(
                                    memberWorkspace?.admin?.map((member) => (
                                        <div key={member.uuid}>
                                            <div className="flex items-center gap-1 justify-between border-b border-backgray py-3">
                                                <div className="flex gap-4 items-center">
                                                    <div className="flex items-center justify-center bg-azulfy-rich-black min-w-[32px] w-8 h-8 rounded-full">
                                                        <span className="font-comfortaa text-xs font-normal text-white">{member.user?.initials}</span>
                                                    </div>

                                                    <div className="flex flex-col font-comfortaa break-all">
                                                        <h3 className="text-base font-bold text-azulfy-rich-black line-clamp-1">
                                                            {member?.user?.name}
                                                        </h3>

                                                        <p className="text-xs font-normal text-azulfy-rich-black line-clamp-1">
                                                            {member?.user?.email}
                                                        </p>

                                                        <span className="text-xs font-normal text-azulfy-rich-black mt-1 line-clamp-1">
                                                            {member.user?.phone}
                                                        </span>
                                                    </div>
                                                </div>

                                                <Whisper placement="bottomEnd" trigger="click" className="block md:hidden" speaker={
                                                    <Popover>
                                                        <div className="flex flex-col gap-4">
                                                            <SelectPicker
                                                                data={rolesOptions}
                                                                searchable={false}
                                                                style={{ width: '17rem', fontSize: '14px', color: '#061425' }}
                                                                cleanable={false}
                                                                // menuStyle={{ color: '#061425' }}
                                                                className="text-azulfy-rich-black"
                                                                value={selectedRoles[member.user?.uuid!]}
                                                                // defaultValue={member.role_name}
                                                                onSelect={async (value) => {
                                                                    await handleUpdateRole(member.user?.uuid!, value);
                                                                }}
                                                                disabled={Boolean(hasPermissionEditMembers) && member.user?.name === authUser?.user?.name}
                                                            />

                                                            <Permission permissions={['delete members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold text-error hover:opacity-80 whitespace-nowrap disabled:opacity-80"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("delete_user")}
                                                                        </button>
                                                                    </Dialog.Trigger>
                                                                    <ConfirmDeleteMemberModal
                                                                        title={t("are_you_sure_you_want_to_delete_this_user")}
                                                                        name={member.user?.name!}
                                                                        email={member.user?.email!}
                                                                        role={member.role_name!}
                                                                        handleDelete={() => handleDeleteMember(member.user?.uuid!)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>

                                                            <Permission permissions={['edit members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold hover:opacity-80 whitespace-nowrap"
                                                                        >
                                                                            {t("edit")}
                                                                        </button>
                                                                    </Dialog.Trigger>

                                                                    <EditMemberModal
                                                                        title={t("edit_member")}
                                                                        data={{ name: member.user?.name, phone: member.user?.phone as E164Number}}
                                                                        handleEdit={(data, close) => handleEditMember(member.user?.uuid!, data, close)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>
                                                        </div>
                                                    </Popover>
                                                }>
                                                    <p className="block md:hidden">
                                                        <BsThreeDotsVertical size={24} className="text-azulfy-rich-black" />
                                                    </p>
                                                </Whisper>

                                                <div className="font-comfortaa hidden md:flex gap-5">
                                                    <SelectPicker
                                                        data={rolesOptions}
                                                        searchable={false}
                                                        style={{ width: '17rem', fontSize: '14px', color: '#061425' }}
                                                        cleanable={false}
                                                        // menuStyle={{ color: '#061425' }}
                                                        className="text-azulfy-rich-black"
                                                        value={selectedRoles[member.user?.uuid!]}
                                                        // defaultValue={member.role_name}
                                                        onSelect={async (value) => {
                                                            await handleUpdateRole(member.user?.uuid!, value);
                                                        }}
                                                        disabled={Boolean(hasPermissionEditMembers) && member.user?.name === authUser?.user?.name}
                                                    />
                                                    {showButtonAddMembers && (
                                                        <>
                                                            <Permission permissions={['delete members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold text-error hover:opacity-80 whitespace-nowrap disabled:opacity-80"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("delete_user")}
                                                                        </button>
                                                                    </Dialog.Trigger>
                                                                    <ConfirmDeleteMemberModal
                                                                        title={t("are_you_sure_you_want_to_delete_this_user")}
                                                                        name={member.user?.name!}
                                                                        email={member.user?.email!}
                                                                        role={member.role_name!}
                                                                        handleDelete={() => handleDeleteMember(member.user?.uuid!)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>

                                                            <Permission permissions={['edit members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold hover:opacity-80 whitespace-nowrap"
                                                                        >
                                                                            {t("edit")}
                                                                        </button>
                                                                    </Dialog.Trigger>

                                                                    <EditMemberModal
                                                                        title={t("edit_member")}
                                                                        data={{ name: member.user?.name, phone: member.user?.phone as E164Number}}
                                                                        handleEdit={(data, close) => handleEditMember(member.user?.uuid!, data, close)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>

                            <div>
                                {memberWorkspace?.technicians?.length > 0 && (
                                    <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                        {t('technicians')}
                                    </p>
                                )}

                                {memberWorkspace?.technicians?.map((member) => (
                                        <div key={member.uuid}>
                                            <div className="flex items-center gap-1 justify-between border-b border-backgray py-3">
                                                <div className="flex gap-4 items-center">
                                                    <div className="flex items-center justify-center bg-azulfy-rich-black min-w-[32px] w-8 h-8 rounded-full">
                                                        <span className="font-comfortaa text-xs font-normal text-white">{member.user?.initials}</span>
                                                    </div>

                                                    <div className="flex flex-col font-comfortaa break-all">
                                                        <h3 className="text-base font-bold text-azulfy-rich-black line-clamp-1">
                                                            {member?.user?.name}
                                                        </h3>

                                                        <p className="text-xs font-normal text-azulfy-rich-black line-clamp-1">
                                                            {member?.user?.email}
                                                        </p>

                                                        <span className="text-xs font-normal text-azulfy-rich-black mt-1 line-clamp-1">
                                                            {member.user?.phone}
                                                        </span>
                                                    </div>
                                                </div>

                                                <Whisper placement="bottomEnd" trigger="click" className="block md:hidden" speaker={
                                                    <Popover >
                                                        <div className="flex flex-col gap-4">
                                                            <SelectPicker
                                                                data={rolesOptions}
                                                                searchable={false}
                                                                style={{ width: '17rem', fontSize: '14px', color: '#061425' }}
                                                                cleanable={false}
                                                                // menuStyle={{ color: '#061425' }}
                                                                className="text-azulfy-rich-black"
                                                                value={selectedRoles[member.user?.uuid!]}
                                                                // defaultValue={member.role_name}
                                                                onSelect={async (value) => {
                                                                    await handleUpdateRole(member.user?.uuid!, value);
                                                                }}
                                                                disabled={hasPermissionEditMembers ? false : true}
                                                            />

                                                            <Permission permissions={['delete members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold text-error hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("delete_user")}
                                                                        </button>
                                                                    </Dialog.Trigger>
                                                                    <ConfirmDeleteMemberModal
                                                                        title={t("are_you_sure_you_want_to_delete_this_user")}
                                                                        name={member.user?.name!}
                                                                        email={member.user?.email!}
                                                                        role={member.role_name!}
                                                                        handleDelete={() => handleDeleteMember(member.user?.uuid!)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>

                                                            <Permission permissions={['edit members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("edit")}
                                                                        </button>
                                                                    </Dialog.Trigger>

                                                                    <EditMemberModal
                                                                        title={t("edit_member")}
                                                                        data={{ name: member.user?.name, phone: member.user?.phone as E164Number}}
                                                                        handleEdit={(data, close) => handleEditMember(member.user?.uuid!, data, close)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>
                                                        </div>
                                                    </Popover>
                                                }>
                                                    <p className="block md:hidden">
                                                        <BsThreeDotsVertical size={24} className="text-azulfy-rich-black" />
                                                    </p>
                                                </Whisper>

                                                <div className="font-comfortaa hidden md:flex gap-5">
                                                    <SelectPicker
                                                        data={rolesOptions}
                                                        searchable={false}
                                                        style={{ width: '17rem', fontSize: '14px', color: '#061425' }}
                                                        cleanable={false}
                                                        // menuStyle={{ color: '#061425' }}
                                                        className="text-azulfy-rich-black"
                                                        value={selectedRoles[member.user?.uuid!]}
                                                        // defaultValue={member.role_name}
                                                        onSelect={async (value) => {
                                                            await handleUpdateRole(member.user?.uuid!, value);
                                                        }}
                                                        disabled={hasPermissionEditMembers ? false : true}
                                                    />

                                                    {showButtonAddMembers && (
                                                        <>
                                                            <Permission permissions={['delete members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold text-error hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("delete_user")}
                                                                        </button>
                                                                    </Dialog.Trigger>
                                                                    <ConfirmDeleteMemberModal
                                                                        title={t("are_you_sure_you_want_to_delete_this_user")}
                                                                        name={member.user?.name!}
                                                                        email={member.user?.email!}
                                                                        role={member.role_name!}
                                                                        handleDelete={() => handleDeleteMember(member.user?.uuid!)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>

                                                            <Permission permissions={['edit members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("edit")}
                                                                        </button>
                                                                    </Dialog.Trigger>

                                                                    <EditMemberModal
                                                                        title={t("edit_member")}
                                                                        data={{ name: member.user?.name, phone: member.user?.phone as E164Number}}
                                                                        handleEdit={(data, close) => handleEditMember(member.user?.uuid!, data, close)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>

                            <div>
                                {memberWorkspace?.external_service_provider?.length > 0 && (
                                    <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                        {t('external_service_provider')}
                                    </p>
                                )}
                                {React.Children.toArray(
                                    memberWorkspace?.external_service_provider?.map((member) => (
                                        <div key={member.uuid}>
                                            <div className="flex items-center gap-1 justify-between border-b border-backgray py-3">
                                                <div className="flex gap-4 items-center">
                                                    <div className="flex items-center justify-center bg-azulfy-rich-black min-w-[32px] w-8 h-8 rounded-full">
                                                        <span className="font-comfortaa text-xs font-normal text-white">{member.user?.initials}</span>
                                                    </div>

                                                    <div className="flex flex-col font-comfortaa break-all">
                                                        <h3 className="text-base font-bold text-azulfy-rich-black line-clamp-1">
                                                            {member?.user?.name}
                                                        </h3>

                                                        <p className="text-xs font-normal text-azulfy-rich-black line-clamp-1">
                                                            {member?.user?.email}
                                                        </p>

                                                        <span className="text-xs font-normal text-azulfy-rich-black mt-1 line-clamp-1">
                                                            {member.user?.phone}
                                                        </span>
                                                    </div>
                                                </div>

                                                <Whisper placement="bottomEnd" trigger="click" className="block md:hidden" speaker={
                                                    <Popover >
                                                        <div className="flex flex-col gap-4">
                                                            <SelectPicker
                                                                data={rolesOptions}
                                                                searchable={false}
                                                                style={{ width: '17rem', fontSize: '14px', color: '#061425' }}
                                                                cleanable={false}
                                                                // menuStyle={{ color: '#061425' }}
                                                                className="text-azulfy-rich-black"
                                                                value={selectedRoles[member.user?.uuid!]}
                                                                // defaultValue={member.role_name}
                                                                onSelect={async (value) => {
                                                                    await handleUpdateRole(member.user?.uuid!, value);
                                                                }}
                                                                disabled={hasPermissionEditMembers ? false : true}
                                                            />

                                                            <Permission permissions={['delete members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold text-error hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("delete_user")}
                                                                        </button>
                                                                    </Dialog.Trigger>
                                                                    <ConfirmDeleteMemberModal
                                                                        title={t("are_you_sure_you_want_to_delete_this_user")}
                                                                        name={member.user?.name!}
                                                                        email={member.user?.email!}
                                                                        role={member.role_name!}
                                                                        handleDelete={() => handleDeleteMember(member.user?.uuid!)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>

                                                            <Permission permissions={['edit members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("edit")}
                                                                        </button>
                                                                    </Dialog.Trigger>

                                                                    <EditMemberModal
                                                                        title={t("edit_member")}
                                                                        data={{ name: member.user?.name, phone: member.user?.phone as E164Number}}
                                                                        handleEdit={(data, close) => handleEditMember(member.user?.uuid!, data, close)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>
                                                        </div>
                                                    </Popover>
                                                }>
                                                    <p className="block md:hidden">
                                                        <BsThreeDotsVertical size={24} className="text-azulfy-rich-black" />
                                                    </p>
                                                </Whisper>
                                                <div className="font-comfortaa hidden md:flex gap-5">
                                                    <SelectPicker
                                                        data={rolesOptions}
                                                        searchable={false}
                                                        style={{ width: '17rem', fontSize: '14px', color: '#061425' }}
                                                        cleanable={false}
                                                        // menuStyle={{ color: '#061425' }}
                                                        className="text-azulfy-rich-black"
                                                        value={selectedRoles[member.user?.uuid!]}
                                                        // defaultValue={member.role_name}
                                                        onSelect={async (value) => {
                                                            await handleUpdateRole(member.user?.uuid!, value);
                                                        }}
                                                        disabled={hasPermissionEditMembers ? false : true}
                                                    />
                                                    {showButtonAddMembers && (
                                                        <>
                                                            <Permission permissions={['delete members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold text-error hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("delete_user")}
                                                                        </button>
                                                                    </Dialog.Trigger>
                                                                    <ConfirmDeleteMemberModal
                                                                        title={t("are_you_sure_you_want_to_delete_this_user")}
                                                                        name={member.user?.name!}
                                                                        email={member.user?.email!}
                                                                        role={member.role_name!}
                                                                        handleDelete={() => handleDeleteMember(member.user?.uuid!)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>

                                                            <Permission permissions={['edit members workspace']}>
                                                                <Dialog.Root>
                                                                    <Dialog.Trigger asChild>
                                                                        <button
                                                                            className="text-sm font-bold hover:opacity-80 whitespace-nowrap"
                                                                            disabled={member.user?.name === authUser?.user?.name ? true : false}
                                                                        >
                                                                            {t("edit")}
                                                                        </button>
                                                                    </Dialog.Trigger>

                                                                    <EditMemberModal
                                                                        title={t("edit_member")}
                                                                        data={{ name: member.user?.name, phone: member.user?.phone as E164Number}}
                                                                        handleEdit={(data, close) => handleEditMember(member.user?.uuid!, data, close)}
                                                                    />
                                                                </Dialog.Root>
                                                            </Permission>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </>
                )}

            </div>
        </>
    )
}
