import { HTMLAttributes, useEffect, useState } from "react";

interface AlertImageProps extends HTMLAttributes<HTMLImageElement> {
    imageUrl: string;
    altText: string;
    onlyImage?: boolean;
}

export function AlertImage({ imageUrl, altText, onlyImage = false }: AlertImageProps) {
    const [isImageValid, setIsImageValid] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => setIsImageValid(true);
        img.onerror = () => setIsImageValid(false);
    }, [imageUrl]);

    return (
        <>
            {isImageValid && (
                <>
                    {onlyImage ? (
                        <img
                            src={imageUrl}
                            alt={altText}
                            className="w-full rounded-xl mt-5 max-h-60 object-contain"
                        />
                    ) : (
                        <div className="w-[90px] max-h-[52px] relative bg-black-100 rounded-[4px] shadow-2xl group cursor-pointer">
                            <img
                                className="w-full rounded-[4px] max-h-[52px] object-contain lg:group-hover:opacity-70 transition duration-300"
                                src={imageUrl}
                                alt={altText}
                            />
                            <span className='absolute z-[2] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.99998 7.4987L3.83331 3.33203M3.83331 3.33203V6.66536M3.83331 3.33203H7.16665M13 7.4987L17.1666 3.33203M17.1666 3.33203V6.66536M17.1666 3.33203H13.8333M7.99998 12.4987L3.83331 16.6654M3.83331 16.6654V13.332M3.83331 16.6654H7.16665M13 12.4987L17.1666 16.6654M17.1666 16.6654V13.332M17.1666 16.6654H13.8333" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                    )}
                </>
            )}

        </>
    );
}