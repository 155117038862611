import { useUser } from "hooks/useUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useLocation, useSearchParams } from "react-router-dom";
import Permission from "utils/permission";

export default function Sidebar() {
  const { authUser } = useUser();
  const [active, setActive] = useState<
    "home" | "council" | "account" | "alerts" | "support" | "licenses"
  >("home");

  const location = useLocation();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const alertUuid = searchParams.get("alert");

  useEffect(() => {
    var path = location.pathname;
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (path.includes("/home")) {
      setActive("home");
    } else if (path.startsWith("/workspace")) {
      if (path.endsWith("/account")) {
        setActive("account");
      } else if (path.split("/").pop() && uuidRegex.test(path.split("/").pop()!)) {
        setActive("council");
      } else if (path.includes("/support")) {
        setActive("support");
      } else if (path.includes("/alerts")) {
        setActive("alerts");
      }
    } else if (path.includes("/alerts")) {
      setActive("alerts");
    } else if (path.includes("/support")) {
      setActive("support");
    } else if (path.includes("/account")) {
      setActive("account");
    } else if (path.includes("/licenses")) {
      setActive("licenses");
    }
  }, [location]);

  return (
    <div className="hidden md:flex flex-col justify-start items-start gap-5 font-raleway w-[150px] fixed">
      {authUser?.roles &&
        authUser?.roles !== "external_service_provider" && (
          <Link
            className={`text-sm text-azulfy-rich-black hover:opacity-70 ${active === "home" && "!text-azulfy-blue underline font-bold"
              }`}
            to={authUser?.roles === "super_admin" ? "/home" : `/workspace/${authUser?.workspaces?.uuid}/home`}
          >
            {t("home")}
          </Link>
        )}
      {authUser?.roles && authUser?.roles == "admin" && (
        <Link
          className={`text-sm text-azulfy-rich-black hover:opacity-70 ${active === "council" && "!text-azulfy-blue underline font-bold"
            }`}
          to={`/workspace/${authUser?.workspaces?.uuid}`}
        >
          {t("council")}
        </Link>
      )}
      <Link
        className={`text-sm text-azulfy-rich-black hover:opacity-70 ${active === "account" && "!text-azulfy-blue underline font-bold"
          }`}
        to={authUser?.roles === "super_admin" ? "/account" : `/workspace/${authUser?.workspaces?.uuid}/account`}
      >
        {t("account")}
      </Link>
      {authUser?.roles && authUser?.roles !== "super_admin" && (
        <>
          <Link
            className={`text-sm text-azulfy-rich-black hover:opacity-70 ${active === "alerts" && "!text-azulfy-blue underline font-bold"
              }`}
            to={alertUuid ? `/workspace/${authUser?.workspaces?.uuid}/alerts?alert=${alertUuid}` : `/workspace/${authUser?.workspaces?.uuid}/alerts`}
          >
            {t("alerts")}
          </Link>
        </>
      )}
      <Permission permissions={['view message customer support']}>
        <Link
          className={`text-sm text-azulfy-rich-black hover:opacity-70 ${active === "support" && "!text-azulfy-blue underline font-bold"
            }`}
          to={`${authUser?.roles === "super_admin" ? "/support" : `${`/workspace/${authUser?.workspaces?.uuid}/support/${authUser?.support?.uuid}`}`}`}
        >
          {t("customer_support")}
        </Link>
      </Permission>
      {authUser?.roles && authUser?.roles == "super_admin" && (
        <>
          <Link
            className={`text-sm text-azulfy-rich-black hover:opacity-70 ${active === "licenses" && "!text-azulfy-blue underline font-bold"
              }`}
            to={`/licenses`}
          >
            {t("manage_licenses")}
          </Link>
        </>
      )}
    </div>
  );
}
