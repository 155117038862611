import * as Dialog from "@radix-ui/react-dialog";
import * as Popover from "@radix-ui/react-popover";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { animated, useTransition } from "react-spring";

import { useUser } from "hooks/useUser";

import SidebarMobile from "components/SidebarMobile";

import { BsChevronLeft, BsList, BsXCircleFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import logoAzulfy from "../../assets/logo_azulfy.png";
import { useAlerts } from "hooks/useAlerts";
import { useWorkspaces, Workspace } from "hooks/useWorkspaces";

interface HeaderProps {
  position?: "fixed" | "absolute";
}

export default function Header({ position = "fixed" }: HeaderProps) {
  const [openSettings, setOpenSettings] = useState(false);
  const { authUser, logout } = useUser();
  const { setWorkspace } = useWorkspaces();
  const { alertSelectedHome } = useAlerts();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerHeaderRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const transitions = useTransition(openSettings, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300, tension: 200, velocity: 200 },
  });

  const disabledArrow = location.pathname === `/home` || location.pathname.endsWith(`/home`) || location.pathname.endsWith(`/alerts`);

  useEffect(() => {
    if (alertSelectedHome) {
      containerHeaderRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [alertSelectedHome]);

  return (
    <div ref={containerHeaderRef} className="flex items-center w-full justify-center">
      <header className={`py-5 gap-1 w-[90%] max-lg:w-[95%] flex items-center justify-between ${position === "fixed" ? "fixed" : "absolute"} min-h-[80px] top-0 bg-white z-[15]`}>
        <div className="flex gap-2">
          {!disabledArrow && authUser?.isAuthenticated && (
            <button className="block md:hidden">
              <BsChevronLeft
                size={24}
                className="text-azulfy-blue"
                onClick={() => navigate(-1)}
              />
            </button>
          )}
          <Link to={authUser?.roles === "super_admin" ? "/home" : !authUser?.isAuthenticated ? `/login` : authUser.error == 160 ? `/approval-process` : authUser.error == 161 ? `/invite-request` : `/workspace/${authUser?.workspaces?.uuid}/home`}>
            <img
              src={logoAzulfy}
              alt="Azulfy logo"
              className="md:w-[132px] md:h-[32px] max-md:w-[100px] max-md:h-[24px]"
            />
          </Link>
        </div>
        {authUser?.isAuthenticated && (

          <>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="flex items-center gap-2 ml-auto mr-5 max-w-[150px] md:max-w-[200px]">
                  <span className="w-10 h-10 max-md:w-8 max-md:h-8 rounded-full flex-shrink-0 flex items-center justify-center bg-azulfy-blue text-white hover:text-white hover:no-underline hover:bg-azulfy-blue-hover text-lg max-md:text-sm font-comfortaa">
                    {authUser?.user?.initials || ""}
                  </span>
                  <div className="flex flex-col items-start hover:opacity-70 font-raleway">
                    <span className="text-base font-semibold line-clamp-1 text-start">
                      {authUser?.user?.name}
                    </span>
                    <span className="text-xs line-clamp-1 text-start">
                      {authUser?.roles === "admin" && <>{t("admin")}</>}
                      {authUser?.roles === "super_admin" && <>{t("super_admin")}</>}
                      {authUser?.roles === "technicians" && <>{t("technicians")}</>}
                      {authUser?.roles === "external_service_provider" && (
                        <>{t("external_service_provider")}</>
                      )}
                    </span>
                  </div>
                </button>
              </Popover.Trigger>
              <Popover.Content
                sideOffset={3}
                align="end"
                className="bg-white p-4 shadow-lg rounded-md border border-grey-400 border-opacity-30"
              >
                <button
                  className="flex items-center gap-2 hover:opacity-70"
                  onClick={() => {
                    logout();
                    setWorkspace({} as Workspace);
                  }}
                >
                  <FiLogOut size={20} />
                  <span>{t("logout_out")}</span>
                </button>
              </Popover.Content>
            </Popover.Root>


            {/* <div className="hidden px-4 py-2 bg-gray-100 rounded-md border border-gray-400 md:flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            placeholder={`${t("search")}...`}
            className="focus:outline-none bg-transparent w-full text-azulfy-rich-black"
          />
          <button>
            <BsSearch size={16} className="text-azulfy-rich-black" />
          </button>
        </div> */}

            {/* MOBILE */}

            <div className="md:hidden flex gap-5 items-center">
              <Dialog.Root open={openSettings} onOpenChange={setOpenSettings}>
                <Dialog.Trigger asChild>
                  <button>
                    <BsList size={24} className="text-azulfy-blue" />
                  </button>
                </Dialog.Trigger>
                {transitions((styles, item) =>
                  item ? (
                    <Dialog.Portal>
                      <Dialog.Overlay
                        style={{
                          position: "fixed",
                          width: "100vw",
                          height: "100vh",
                          inset: 0,
                          background: "rgba(0, 0, 0, 0.75)",
                          zIndex: "10",
                        }}
                      />
                      <Dialog.Content
                        forceMount
                        className={
                          "z-[1000] w-screen h-screen p-4 bottom-0 left-0 fixed bg-white"
                        }
                      >
                        <animated.div style={styles}>
                          <button
                            onClick={() => setOpenSettings(false)}
                            className="absolute top-4 right-4"
                          >
                            <BsXCircleFill size={25} className="text-azulfy-blue" />
                          </button>
                          <SidebarMobile setOpenSettings={setOpenSettings} />
                        </animated.div>
                      </Dialog.Content>
                    </Dialog.Portal>
                  ) : null
                )}
              </Dialog.Root>
            </div>
          </>
        )}
      </header>
    </div>
  );
}
