import { useEffect, useState } from "react";
import {
  BsChevronLeft,
  BsChevronRight,
  BsX,
} from "react-icons/bs";
import * as Dialog from "@radix-ui/react-dialog";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { AlertImageProps } from "hooks/useAlerts";

interface ImagesAlertModalProps {
  attachments: AlertImageProps[];
}

export default function ImagesAlertModal({
  attachments,
}: ImagesAlertModalProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === attachments.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? attachments.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNextImage();
      } else if (event.key === "ArrowLeft") {
        handlePreviousImage();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [attachments.length]);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "50",
        }}
      />
      <Dialog.Content
        forceMount
        // onKeyDown={handleNextImage}
        className={
          "z-[100] rounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed flex flex-col items-center gap-5 "
        }
      >
        <Dialog.Close
          className={
            "z-[101] absolute top-3 right-3 rounded-full focus:outline-none bg-error hover:opacity-70"
          }
        >
          <BsX color="#fff" size={20} />
        </Dialog.Close>
        <div className="w-full h-full relative contents">
          <TransformWrapper pinch={{ disabled: false }}>
            <TransformComponent>
              <div className="relative bg-black-100 rounded-[10px] w-[85vw] h-[250px] md:w-[600px] md:h-[400px] lg:w-[800px] lg:h-[533px]">
                <img
                  className="w-full h-full object-contain max-h-full rounded-[10px]"
                  src={attachments[currentIndex].url}
                />
              </div>
            </TransformComponent>
          </TransformWrapper>
          {currentIndex < attachments.length - 1 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleNextImage();
              }}
              className="z-[101] absolute top-[50%] -translate-y-[50%] right-4 rounded-full bg-azulfy-blue p-1 flex items-center justify-center hover:opacity-70"
            >
              <BsChevronRight size={24} color="#fff" />
            </button>
          )}

          {currentIndex > 0 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handlePreviousImage();
              }}
              className="z-[101] absolute top-[50%] -translate-y-[50%] left-4 rounded-full bg-azulfy-blue p-1 flex items-center justify-center hover:opacity-70"
            >
              <BsChevronLeft size={24} color="#fff" />
            </button>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}