export const defaultDrawStyles = [
  {
    // Already drawed polygon, the user clicks outside the polygon
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "fill-color": "#222222",
      "fill-outline-color": "#222222",
      "fill-opacity": 0.15, // Change here
    },
  },
  {
    // Polygon drawing area
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      //'fill-color': '#0072FF',
      // 'fill-outline-color': '#222222',
      "fill-opacity": 0.25, // Change here
    },
  },

  {
    // Polygon inactive line
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#0072FF",
      "line-width": 2,
    },
  },
  {
    // Polygon drawing direct select stroke
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: [
      "all",
      ["==", "mode", "direct_select"],
      ["==", "active", "true"],
      ["==", "$type", "Polygon"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#0072FF",
      "line-dasharray": [6, 8],
      "line-width": 1,
    },
  },
  {
    // Polygon simple select stroke
    id: "gl-draw-polygon-stroke-active-simple-select",
    type: "line",
    filter: [
      "all",
      ["==", "mode", "simple_select"],
      ["==", "active", "true"],
      ["==", "$type", "Polygon"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#0072FF",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#3bb2d0",
      "line-width": 2,
    },
  },
  {
    // Polygon first drawing line
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#627D98",
      "line-dasharray": [4, 4],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
  {
    // Polygon main points
    id: "gl-draw-polygon-and-line-vertex-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 8,
      "circle-color": "#0072FF",
    },
  },
  {
    id: "gl-draw-point-point-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 12,
      "circle-opacity": 1,
      "circle-color": "#0072FF",
    },
  },
  
 
  {
    id: "gl-draw-point-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 10,
      "circle-color": "#0072FF",
      "circle-stroke-width": 2, // Largura da borda do círculo
      "circle-stroke-color": "#0072FF",
    },
  },
  {
    id: "gl-draw-point-stroke-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": 7,
      "circle-color": "#fff",
    },
  },
  {
    // Polygon active point
    id: "gl-draw-point-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 12,
      "circle-color": "#0072FF",
      "circle-stroke-width": 2, 
      "circle-stroke-color": "#0072FF",
    },
  },
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#0072FF",
      "fill-outline-color": "#0072FF",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#0072FF",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#404040",
      "line-width": 2,
    },
  },
  {
    // Polygon active point
    id: "gl-draw-point-static",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 10,
      "circle-color": "#0072FF",
      "circle-stroke-width": 2, // Largura da borda do círculo
      "circle-stroke-color": "#0072FF",
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 0,
      "circle-color": "#0072FF",
    },
  },
  {
    id: "gl-draw-polygon-color-picker",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["has", "user_portColor"]],
    paint: {
      "fill-color": ["get", "user_portColor"],
      "fill-outline-color": ["get", "user_portColor"],
      "fill-opacity": 0.5,
    },
  },
  {
    id: "gl-draw-line-color-picker",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["has", "user_portColor"]],
    paint: {
      "line-color": ["get", "user_portColor"],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-color-picker",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["has", "user_portColor"]],
    paint: {
      "circle-radius": 3,
      "circle-color": ["get", "user_portColor"],
    },
  },
  // {
  //   id: "custom-point",
  //   type: "symbol",
  //   layout: {
  //     "text-field": "A",
  //     "text-size": 14,
  //     "text-anchor": "center",
  //     "text-justify": "auto",
  //   },
  //   paint: {
  //     "text-color": "#fff",
  //   },
  // },
];
