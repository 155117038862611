import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { useUser } from "hooks/useUser";
import { zodResolver } from "@hookform/resolvers/zod";
import { useWorkspaces } from "hooks/useWorkspaces";

import { InputLogin } from "components/InputLogin";
import { LinearButton } from "components/LinearButton";

import loginLogo from "assets/login-logo.png";
import { toast } from "utils/toast";

const loginFormSchema = z.object({
  email: z.string(),
  password: z.string(),
});

type loginFormInputs = z.infer<typeof loginFormSchema>;

export default function LoginComponent() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<loginFormInputs>({
    resolver: zodResolver(loginFormSchema),
  });
  const { login, authUser } = useUser();
  const navigate = useNavigate();
  const { checkIfEmailExists } = useWorkspaces();

  async function handleLogin(data: loginFormInputs) {
    try {
      const response = await login(data);
      await checkIfEmailExists(data.email);
      if (response) {
        if (response.roles === "super_admin") {
          navigate("/home");
        } else {
          if (response.error === 160) {
            navigate('/approval-process')
          } else if (response.error === 161) {
            navigate('/invite-request')
          } else {
            navigate(`/workspace/${response.workspaces.uuid}/home`);
          }
        }
      }
    } catch (error: any) {
      toast({
        label: "Error",
        message: error?.response?.data?.content?.message,
        type: "error",
      });
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-full text-azulfy-rich-black max-md:pb-20 px-10 max-h-screen overflow-y-auto">
      <img
        className="w-[100px] max-md:w-[75px]"
        src={loginLogo}
        alt="Azulfy logo"
      />
      <span className="font-raleway text-lg hidden md:block mt-3">
        Let’s Azulfy the world together.
      </span>
      <form
        onSubmit={handleSubmit(handleLogin)}
        className="flex flex-col gap-12 md:mt-20 max-md:mt-12"
      >
        <div className="flex flex-col">
          <h1 className="text-5xl max-md:text-3xl text-azulfy-rich-black mb-2 text-center md:min-w-[350px]">
            {authUser && authUser?.isAuthenticated === false
              ? "Welcome Back!"
              : "Welcome!"}
          </h1>
          <span className="text-center font-comfortaa text-azulfy-rich-black">
            Login to your account
          </span>
        </div>

        <div className="flex flex-col gap-5">
          <InputLogin
            {...register("email")}
            icon="email"
            type="email"
            required
            placeholder="E-mail"
          />
          <InputLogin
            {...register("password")}
            icon="password"
            type="password"
            required
            placeholder="Password"
          />
          <div className="flex items-center justify-between ml-auto">
            {/* <span className="flex items-center">
              <input type="checkbox" />
              <p className="font-raleway ml-2 max-md:text-xs">Remember me</p>
            </span> */}
            <Link
              className="text-azulfy-blue font-raleway hover:underline max-md:text-xs"
              to={"/forget-password"}
            >
              Forgot your password?
            </Link>
          </div>
        </div>
        <LinearButton disabled={isSubmitting} type="submit" text="Login" />
      </form>
    </div>
  );
}
