import Loading from "components/Loading";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert, useAlerts } from "hooks/useAlerts";
import { useUser } from "hooks/useUser";

import { BiWater } from "react-icons/bi";
import { BsChevronRight, BsPeople } from "react-icons/bs";
import { GiGroundSprout } from "react-icons/gi";
import { TbWind } from "react-icons/tb";

import { AboutAlert } from "components/AboutAlert";
import { FilterProps, IndicatorsProps, useFilters } from "hooks/useFilters";
import { getAlertStatuses } from "pages/Chat/components/AlertStatuses";
import { useLocation, useNavigate } from "react-router-dom";
import { severityColors } from "utils/severityColors";
import HomeAdminMap from "./HomeAdminMap";

import AboutAlertModal from "components/AboutAlertModal";
import { ChartComponent } from "components/Chart";
import { useIndicatorsHistory } from "hooks/useIndicatorsHistory";
import { Modal } from "rsuite";
import emptyStateAlertInfo from '../../../assets/empty-state-alert-info.svg';
import emptyStateNoAlert from '../../../assets/empty-state-no-alerts.svg';

export default function HomeAdmin() {
  const { t } = useTranslation();
  const { getAllFilters } = useFilters();
  const { indicatorsHistory, setIndicatorsHistory, showGraph, setShowGraph, whereSelected, whatSelected, whenSelected, setWhatSelected, setWhenSelected, setWhereSelected } = useIndicatorsHistory();
  const { getAlerts, alerts, alertSelectedHome, setAlertSelectedHome, selectedAlert, setSelectedAlert } = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const [openModalAlertInfo, setOpenModalAlertInfo] = useState(false);
  const containerHomeAdminRef = useRef<HTMLDivElement>(null);

  const [alertInfo, setAlertInfo] = useState<Alert | null>(null);

  const { authUser } = useUser();

  const statuses = getAlertStatuses();

  const handleOpenModalAlertInfo = () => {
    setOpenModalAlertInfo(true)
  };
  const handleCloseModalAlertInfo = () => {
    setOpenModalAlertInfo(false)
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const newDate = dayjs();
  const lastMonth = dayjs().subtract(29, "days");

  const newDateTimestamp = newDate.unix();
  const lastMonthTimestamp = lastMonth.unix();

  useEffect(() => {
    if (alertSelectedHome) {
      setSelectedAlert(alertSelectedHome);
    }
  }, [alertSelectedHome]);

  async function getFilters() {
    await getAllFilters();
  }

  useEffect(() => {
    getFilters();
  }, []);

  async function getAllAlerts() {
    setIsLoading(true);
    await getAlerts(`?page=1&sortBy=alert_datetime&sortDirection=desc&take=5000&lt=${lastMonthTimestamp}&gt=${newDateTimestamp}`);
    setIsLoading(false);
  }

  useEffect(() => {
    getAllAlerts();
  }, []);

  const [lastWhereSelected, setLastWhereSelected] = useState<FilterProps | null>(null);
  const [lastWhatSelected, setLastWhatSelected] = useState<IndicatorsProps | null>(null);

  useEffect(() => {
    if (whereSelected !== lastWhereSelected) {
      setWhatSelected(null);
      setWhenSelected(null);
      setLastWhereSelected(whereSelected);
    }
  }, [whereSelected, lastWhereSelected]);


  useEffect(() => {
    if (whatSelected !== lastWhatSelected) {
      setWhenSelected(null);
      setLastWhatSelected(whatSelected);
    }
  }, [whatSelected, lastWhatSelected]);

  useEffect(() => {
    if (showGraph) {
      setShowGraph(false);
    }
  }, [showGraph]);

  const newAlerts = useMemo(() => {
    let alertsFiltered = alerts;
    let whenAlert = false;

    if (showGraph) {
      return alerts;
    }
    
    if (whereSelected) {
      const filter = alertsFiltered?.filter((alert) => alert?.indicators.filter_id === whereSelected?.id);
      alertsFiltered = filter;
    } else {
      return alertsFiltered;
    }
    if (whatSelected) {
      const filter = alertsFiltered?.filter((alert) => alert?.indicators.id === whatSelected?.id);
      alertsFiltered = filter;
    }
    if (whenSelected !== null && !whenAlert) {
      const filter = alertsFiltered?.filter((alert) => {
        const alertDate = new Date(alert.alert_datetime);
        const alertFiltered = alertDate >= whenSelected.startDate && alertDate <= whenSelected.endDate
        return alertFiltered;
      });
      whenAlert = true;
      alertsFiltered = filter;
    }
    return alertsFiltered;
  }, [whereSelected, whatSelected, whenSelected, alerts, lastWhereSelected, lastWhatSelected, showGraph]);

  const scrollToTop = () => {
    containerHomeAdminRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[400px]">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-10 pb-12" ref={containerHomeAdminRef}>
          <div className="px-5 md:px-0">
            <HomeAdminMap
              markRedirectUrl={`/workspace/${authUser?.workspaces?.uuid}/alerts/?alert=:uuid`}
              alerts={newAlerts}
            />
          </div>
          <div className="relative lg:grid w-full h-full max-w-6xl flex flex-col-reverse gap-8 mx-auto lg:grid-cols-2">
            <div className="flex flex-col gap-3">
              <h3 className="text-xl font-bold font-comfortaa text-azulfy-blue">
                {newAlerts?.length} {newAlerts?.length === 1 ? t("alert") : t("alerts")}
              </h3>
              {newAlerts && newAlerts?.length > 0 ? (
                <div className="flex flex-col w-full max-h-[50rem] overflow-y-auto text-azulfy-rich-black">
                  <>
                    {newAlerts &&
                      newAlerts?.map((alert) => {
                        const dateFormatted = dayjs(alert.alert_datetime).format(
                          "DD/MM/YYYY"
                        );
                        const isSelected = selectedAlert === alert.uuid;
                        return (
                          <div
                            onClick={() => {
                              // navigate(
                              //   `/workspace/${authUser?.workspaces?.uuid}/alerts?alert=${alert.uuid}`
                              // );
                              setSelectedAlert(alert.uuid);
                              setAlertInfo(alert);
                              setIndicatorsHistory(null);
                              setAlertSelectedHome(alert.uuid);
                              scrollToTop();
                            }}
                            data-uuid={alert.uuid}
                            key={alert.uuid}
                            className={`flex items-center justify-between gap-2 w-full p-3 border-b border-grey-400 border-opacity-50 cursor-pointer ${isSelected ? "bg-[#ebf4ff]" : ""
                              }`}
                          >
                            <div className="flex flex-col gap-1 w-full">
                              <div className="flex items-center gap-3 w-full">
                                <span
                                  className={`rounded-full`}
                                >
                                  {alert?.indicators.filter_id === 1 && (
                                    <BiWater
                                      color={severityColors.find((color) => color.value === alert.severity)?.label}
                                      size={30}
                                    />
                                  )}
                                  {alert?.indicators.filter_id === 2 && (
                                    <TbWind
                                      color={severityColors.find((color) => color.value === alert.severity)?.label}
                                      size={30}
                                    />
                                  )}

                                  {alert?.indicators.filter_id === 3 && (
                                    <GiGroundSprout
                                      color={severityColors.find((color) => color.value === alert.severity)?.label}
                                      size={30}
                                    />
                                  )}

                                  {alert?.indicators.filter_id === 4 && (
                                    <BsPeople
                                      color={severityColors.find((color) => color.value === alert.severity)?.label}
                                      size={30}
                                    />
                                  )}
                                </span>
                                <div className="flex flex-col items-start gap-1 w-full">
                                  <div className="flex items-baseline justify-between w-full">
                                    <h3
                                      className={`text-sm max-lg:text-xs font-semibold font-comfortaa`}
                                    >
                                      {alert.name}
                                    </h3>
                                    <span
                                      className={`text-xs font-raleway`}
                                    >
                                      {dateFormatted}
                                    </span>
                                  </div>
                                  <div className="flex items-baseline w-full gap-1 font-raleway">
                                    <span
                                      className={`text-xs`}
                                    >
                                      {alert.indicators.name}
                                    </span>
                                    <span className={`text-xs`}>
                                      -
                                    </span>
                                    <span
                                      className={`text-xs line-clamp-1`}
                                    >
                                      {statuses.find((status) => status.value === alert?.status?.type)?.label}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center justify-between gap-3 w-full text-[10px] font-bold mt-2">
                                <button
                                  className="lg:hidden flex items-center text-xs justify-center gap-1 bg-white rounded-[10px] shadow py-2 px-4 w-full"
                                  onClick={() => {
                                    handleOpenModalAlertInfo();
                                  }}
                                >
                                  {t("alert_info")}
                                  <BsChevronRight size={10} />
                                </button>
                                <Modal open={openModalAlertInfo} onClose={() => {
                                  handleCloseModalAlertInfo();
                                }}>
                                  <AboutAlertModal alert={alertInfo!} />
                                </Modal>
                                <button
                                  className="hidden lg:flex items-center text-xs justify-center gap-1 bg-white rounded-[10px] shadow py-2 px-4 w-full hover:opacity-80"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedAlert(alert.uuid);
                                    setAlertInfo(alert);
                                    setIndicatorsHistory(null);
                                  }}
                                >
                                  {t("alert_info")}
                                  <BsChevronRight size={10} />
                                </button>
                                <button
                                  className="flex items-center text-xs justify-center gap-1 bg-white rounded-[10px] shadow py-2 px-4 w-full hover:opacity-80"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    searchParams.set("alert", alert?.uuid || "");
                                    navigate(`/workspace/${authUser?.workspaces?.uuid}/alerts?${searchParams.toString()}`)
                                    setSelectedAlert(null)
                                    setWhereSelected(null)
                                    setAlertSelectedHome(null)
                                  }}
                                >
                                  {t("open_chat_alert")}
                                  <BsChevronRight size={10} />
                                </button>
                              </div>
                            </div>
                            <div className="flex items-center gap-1">
                              {alert.total_unread_messages > 0 && (
                                <span className="bg-[#FC6530] rounded-full w-4 h-4 max-lg:w-3 max-lg:h-3 text-white text-[8px] max-lg:text-[7px] font-semibold py-[3px] max-lg:py-[2px] px-[1px] text-center">
                                  {alert.total_unread_messages}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </>
                </div>
              ) : (
                <div>
                  <p>{t("we_still_dont_have_any_alerts_based_on_your_search_filters")}</p>
                  <img src={emptyStateNoAlert} className="w-72 mt-2" alt={t("we_still_dont_have_any_alerts_based_on_your_search_filters")} />
                </div>
              )}

            </div>
            <div className="flex flex-col gap-3">
              {selectedAlert && alertInfo && !indicatorsHistory ? (
                <>
                  <AboutAlert alert={alertInfo!} />
                </>
              ) : indicatorsHistory ? (
                <ChartComponent />
              ) : (
                <div className="hidden lg:flex flex-col gap-3">
                  <h3 className="text-xl font-bold font-comfortaa text-azulfy-blue">
                    {t("about_the_alert")}
                  </h3>
                  <p>{t("select_the_alert_to_access_the_informations_and_history")}</p>
                  <img src={emptyStateAlertInfo} className="w-72 mt-2" alt={t("select_the_alert_to_access_the_informations_and_history")} />
                </div>
              )}

            </div>
          </div>
        </div>
      )}
    </>
  );
}
