import * as Dialog from "@radix-ui/react-dialog";
import {
  E164Number
} from 'libphonenumber-js/core';
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';

export type EditMemberType = {
  name: string | undefined
  phone: E164Number | undefined | null
}

type EditMemberModalProps  = {
  title: string
  data: EditMemberType
  handleEdit: (data: EditMemberType, close: () => void) => void
}

export default function EditMemberModal({ handleEdit, title, data }: EditMemberModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);
  const [phone, setPhone] = useState<E164Number | undefined | null>(data.phone);
  const [name, setName] = useState<string | undefined>(data.name);

  const close = () => {
    closeButton.current?.click()
  }

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.75)",
          zIndex: "16",
        }}
      />

      <Dialog.Content
        forceMount
        className={
          "z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-comfortaa"
        }
      >
        <Dialog.Close ref={closeButton} className={"hidden"}></Dialog.Close>

        <div className="flex flex-col gap-5">
          <h3 className="text-azulfy-blue font-bold text-base">{title}</h3>

          <div className="w-full max-w-lg mx-auto mt-2 flex flex-col gap-4">
            <input
              type="text"
              name="name"
              id="name"
              autoFocus
              value={name}
              onChange={(value) => {
                setName(value.target.value);
              }}
              placeholder={t('name')}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none"
            />

            <PhoneInput
              type="tel"
              name="phone"
              id="phone"
              value={phone!}
              onChange={(value) => {
                if(value){
                  setPhone(value);
                } else {
                  setPhone(null);
                }
              }}
              placeholder={t('phone_number')}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 [&_*:focus]:outline-none [&_*]:text-base"
            />
          </div>

          <div className="flex items-center justify-center gap-4">
            <button
              onClick={close}
              className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 font-bold bg-opacity-20 hover:opacity-70 text-azulfy-rich-black"
            >
              {t("cancel")}
            </button>

            <button
              onClick={() => handleEdit({ name, phone }, close)}
              className="flex items-center justify-center text-white font-bold py-2 px-3 rounded-md bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover active:bg-Azulfy-Linear-Gradient-pressed"
            >
              {t("save")}
            </button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
