import { Progress } from "rsuite";

import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { classNames } from "utils";

interface ProgressHeaderSuperAdminProps {
  step: number;
  handlePrevious: () => void;
  handleNext: () => void;
  handleCreateNewWorkspace(): Promise<void>;
  title: string;
  className?: string
}

export default function ProgressHeaderSuperAdmin({
  step,
  handlePrevious,
  handleNext,
  handleCreateNewWorkspace,
  title,
  className,
}: ProgressHeaderSuperAdminProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <header className={classNames(className, "md:pb-16 pb-8")}>
      <div className="flex justify-between items-center w-full relative">
        <div className="flex gap-2 items-center">
          <button
            className="hover:opacity-80"
            onClick={step === 50 ? () => navigate(-1) : handlePrevious}
          >
            <BsChevronLeft size={20} className="text-azulfy-blue" />
          </button>

          <p className="text-azulfy-blue w-[80%] hidden md:block md:w-full text-xl font-bold font-comfortaa">
            {title}
          </p>
        </div>

        <span className="font-comfortaa text-base font-bold text-azulfy-blue absolute left-1/2">
          {step === 50 ? "1" : "2"}
          /2
        </span>

        {step === 100 ? (
          <button
            className={`font-comfortaa text-xl text-azulfy-blue font-bold hover:opacity-80`}
            onClick={handleCreateNewWorkspace}
          >
            {t("save")}
          </button>
        ) : (
          <button
            className={`font-comfortaa text-xl text-azulfy-blue font-bold hover:opacity-80`}
            onClick={handleNext}
          >
            {t("next")}
          </button>
        )}
      </div>

      <Progress.Line percent={step} showInfo={false} strokeColor="#0072FF" />

      <p className="text-azulfy-blue text-center md:hidden md:w-full text-xl pt-8 font-bold font-comfortaa">
        {title}
      </p>
    </header>
  );
}
